import API from './API';
import { API as APIService } from './API';

const path = `mecEscolas`;

const SchoolService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // EDITAR
    edit: async (id, school) => await API.edit(path, id, school),

    // ADICIONAR
    add: async school => await API.add(path, school),

    // REMOVER
    remove: async id => await API.remove(path, id),

    // CONFIRMAR ENVIO DE CONVITES
    inviteConfirm: async ids => await APIService.put(`${path}/confirmarConvites`, ids),

    // LISTAR EMAILS
    listMails: async id => await APIService.get(`${path}/${id}/emails`),
}

export default SchoolService;