import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';

import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { ButtonSecondary } from '../../components/Button/Button';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import ProvaFilter from '../../forms/Inscricao/inscricaoFilter';

import { locationResponsavelParse, verifyLocationResponsavelParse } from '../../helper/ParseStateHelper';
import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

import { numberMask } from '../../helper/MaskHelper';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdMecEscola',
      defaultOrderBy: 'cdMecEscola',
      filtered: false,
      filters: [],
      schools: [],
      loading: true,
      reponsavel: null,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setProvaFilter = f => this.ProvaFilter = f;

  // ICON SAVED
  labelSaved = id => this.enabledSavedChanges(id) ? "Sem Participação de Alunos Sinalizada" : "Alunos Participantes Salvos";
  enabledSavedChanges = id => this.state.schools.find(s => s.cdMecEscola === id).qtdAlunosParticipantes === null;

  loadData = () => {
    let filters = verifyPagingAndSorting(this.state);

    const f = locationResponsavelParse(this.props.location, filters);    

    this.setState({ 
      filters: [...filters], 
      filtered: true, 
      loading: true,
      responsavel: f.find(f => f.field === 'id') ? f.find(f => f.field === 'id').value : null,
    });

    InscricaoService.filter(f)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            ...this.state,
            schools: [...res.data.content], 
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false 
          });
        } else {
          this.setState({ 
            ...this.state,
            schools: [], 
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false 
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })    
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.ProvaFilter ? this.ProvaFilter.state ? this.ProvaFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  // Função para a inserção dos valores no botão de qtd. Alunos participantes
  handleChange = (e, id) => {
    this.setState({
      schools: [...this.state.schools.filter(s => {
        if (s.cdMecEscola === id) s.qtdAlunosParticipantes = numberMask(e.target.value);
        return true
      })],
    });
  }

  // Salvar registro na tabela de inscrição ao retirar o foco do textBox
  handleBlur = (e, id) => {
    const inscricao = this.state.schools.find(s => s.cdMecEscola === id);

    InscricaoService.edit(inscricao.cdInscricaoEscola, inscricao)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "Dados atualizados com sucesso.",
            type: "success",
            open: true
          }
        });
      })
      .catch(error => {
        this.Toast.setState({
          message: {
              message: "Não foi possível atualizar os dados.",
              type: 'error',
              open: true
          }
        })
      });
  } 

  render() {

    const pageName = 'Informar Quantidade de Alunos Presentes na Prova';
    const links = [
      {
        path: !verifyLocationResponsavelParse(this.props.location) ? '/admin/inicio' : null,
        name: 'Início'
      }
    ];

    const columns = [
      { label: 'INEP', name: 'cdMecEscola', func: null, key: true },
      { label: 'Escola', name: 'nmMecEscola', func: null, key: false },
      { label: 'Endereço', name: 'nmEndereco', func: null, key: false },
      { label: 'Qtd. Alunos Inscritos', name: 'qtdAlunosPrevistos', func: null, key: false},
      { label: 'Qtd. Alunos Presentes', name: 'qtdAlunosParticipantes', func: null, blur: this.handleBlur, change: this.handleChange, key: false, item: 'textBox' },
    ];

    const actions = [
      { 
        name: this.labelSaved,
        func: () => true,
        icon: <DoneIcon />, 
        disabled: this.enabledSavedChanges,
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid item container spacing={3}>
          <Grid item sm={12} lg={12}>
            <ProvaFilter 
              parentRef={this.setProvaFilter}
              handleFilterChange={this.filterData}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.schools}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}              
              order={null}
              orderBy={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={() => this.props.history.push({ pathname: `/admin/inicio`, state: { responsavel: this.state.responsavel }})}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </>
    )
  }
}

export default withRouter(List);