import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterPeriodoRegistroParticipacao, isAfterDataProva } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DescriptionIcon from '@material-ui/icons/Description';

import SecretariaService from '../../services/Secretaria';
import { errors } from '../../services/API';

import { toDateTime } from '../../helper/DateHelper';
import { evasaoLimiterMask } from '../../helper/MaskHelper';
import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';
import { patternSendToSecretary } from '../../variables/Enums/Convite';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import SecretariaFilter from '../../forms/Secretaria/SecretariaFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';
import Dialog from '../../forms/Dialog/DialogRemove';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __SECRETARIA_RESPONSAVEL_EDITAR,
  __SECRETARIA_EDITAR,
  __SECRETARIA_REMOVER,
  __SECRETARIA_ADICIONAR,
  __SECRETARIA_EXPORTAR,
  __SECRETARIA_DASHBOARD,
  __SECRETARIA_EMAIL_ENVIAR_INDIVIDUAL,
  __SECRETARIA_EMAIL,
  __RELATORIO_SECRETARIA_VISUALIZAR,
} from '../../security/RoleConfiguration';

export class Secretaria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdSecretariaEducacao',
      defaultOrderBy: 'cdSecretariaEducacao',
      filtered: false,
      filters: [],
      secretaries: [],
      loading: true,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setSecretariaFilter = f => this.SecretariaFilter = f;
  setDialog = f => this.Dialog = f;

  // LABELS
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : "Você não tem permissão para fazer isso";
  labelResponsavel = id => !this.disabledResponsavel(id) ? "Editar Responsável pela Inscrição" : !this.habilitarEdicaoResponsavel(id) ? "Secretaria sem Responsável pela Inscrição vinculado" : "Você não tem permissão para fazer isso";
  labelEnviarEmailIndividual = id => !this.disabledEnviarEmailIndividual(id) ? "Enviar Convite Individual" : !this.habilitarEnvioEmailIndividual(id) ? "Secretaria sem email cadastrado" : "Você não tem permissão para fazer isso";
  labelEmailsEnviados = id => !this.disabledEmailsEnviados(id) ? "Ver Emails Enviados" : "Você não tem permissão para fazer isso";
  labelRelatorio = id => !this.disabledRelatorio(id) ? "Ver Relatório de Secretaria" : !this.comEscolasInscritasEResponsavelParaRelatorio(id) ? "Secretaria sem Escolas Inscritas ou sem Responsável pela Inscrição" : !this.isPeriodoRelatorio() ? "Fora do Período de Download de Relatório" : "Você não tem permissão para fazer isso";
  labelDashboard = id => !this.disabledDashboard(id) ? "Ver Dashboard" : !this.habilitarVisaoDashboard(id) ? "Secretaria sem  por Inscrição vinculado" : "Você não tem permissão para fazer isso";
  labelApagar = id => !this.disabledApagar(id) ? "Apagar" : !this.habilitarRemocaoSecretaria(id) ? "Secretaria já Inscrita e/ou com Responsável pela Inscrição Vinculado" : "Você não tem permissão para fazer isso";

  // VERIFICAR CONTEXTO PARA DOWNLOAD DE RELATORIO
  isPeriodoRelatorio = () => isAfterPeriodoRegistroParticipacao(this.props.contexts) && isAfterDataProva(this.props.contexts);

  // VERIFICAR SE SECRETARIA TEM ESCOLAS Inscritas
  comEscolasInscritasEResponsavelParaRelatorio = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.idResponsavelSecretaria && sec.comEscolasInscritas ? true : false;
  }

  // DISABLES
  disabledEditar = id => !AuthorizedFunction([__SECRETARIA_EDITAR]);
  disabledResponsavel = id => AuthorizedFunction([__SECRETARIA_RESPONSAVEL_EDITAR]) ? !this.habilitarEdicaoResponsavel(id) : !AuthorizedFunction([__SECRETARIA_RESPONSAVEL_EDITAR]) 
  disabledEnviarEmailIndividual = id => AuthorizedFunction([__SECRETARIA_EMAIL_ENVIAR_INDIVIDUAL]) ? !this.habilitarEnvioEmailIndividual(id) : !AuthorizedFunction([__SECRETARIA_EMAIL_ENVIAR_INDIVIDUAL]);
  disabledEmailsEnviados = id => !AuthorizedFunction([__SECRETARIA_EMAIL]);
  disabledRelatorio = id => AuthorizedFunction([__RELATORIO_SECRETARIA_VISUALIZAR]) ? !this.habilitarRelatorio(id) : !AuthorizedFunction([__RELATORIO_SECRETARIA_VISUALIZAR]);
  disabledDashboard = id => AuthorizedFunction([__SECRETARIA_DASHBOARD]) ? !this.habilitarVisaoDashboard(id) : !AuthorizedFunction([__SECRETARIA_DASHBOARD]);
  disabledApagar = id => AuthorizedFunction([__SECRETARIA_REMOVER]) ? !this.habilitarRemocaoSecretaria(id) : !AuthorizedFunction([__SECRETARIA_REMOVER]) ;

  // EDIÇÃO
  handleEditClick = id => { 
    this.props.history.push({ 
      pathname: `/admin/secretaria/editar/${id}`,
      state: { 
        history: { 
          path: `/admin/secretaria`, 
          state: this.state
        }
      }
    })
  }

  // EDIÇÃO RESPONSÁVEL
  handleEditResponsibleClick = id => { 
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    this.props.history.push({ 
      pathname: `/admin/secretaria/responsavel/editar/${sec.idResponsavelSecretaria}`,
      state: { 
        history: { 
          path: `/admin/secretaria`, 
          state: this.state
        }
      }
    })
  }

  // EXPORTAÇÃO
  handleExportPlan = () => {
    this.SecretariaFilter.setState({ filter: true, exportPlan: true, }, () => this.SecretariaFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'success',
        open: true
      }
    })
  }

  // VER LISTAGEM DE EMAILS ENVIADOS
  handleViewSends = id => {
    this.props.history.push({
      pathname: `/admin/secretaria/${id}/emails`,
      state: {
        entity: {
          type: 'SECRETARIA', 
        },
        history: { 
          path: `/admin/secretaria`,
          state: this.state,
        }
      }
    });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  openDialogRemove = id => {
    const secretary = this.state.secretaries.find(secretary => secretary.cdSecretariaEducacao === id);
    this.Dialog.setState({
      dialog: { 
        open: true, 
        title: `Deseja Remover a Secretaria ${secretary.nmSecretariaEducacao} ?`,
        text: `A secretaria após removida não poderá ser recuperada. Não serão possíveis remoções de secretarias que já possuem responsáveis cadastrados e/ou com vínculos de inscrições.`,
        id: id,
        loading: false,
      }
    });
  }

  // REMOÇÃO
  handleRemoveClick = id => {
    this.Dialog.loading();

    SecretariaService.remove(id)
      .then(res => {
        const newSecretaries = this.state.secretaries.filter(secretary => secretary.cdSecretariaEducacao !== id)

        this.setState({ 
          secretaries: [...newSecretaries],
        })

        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: "A Secretaria foi Apagada com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
  }

  // COM RESPOSÁVEL
  comResponsavel = id => (id !== null) ? "Sim" : "Não";

  // COM ESCOLAS INSCRITAS
  comEscolasInscritas = boolean => (boolean !== null && boolean) ? "Sim" : "Não";

  // VERFICAR SE HOUVE ENVIO DE CONVITE PARA MONTAGEM DO DADO DA COLUNA
  verificarEnvioConvite = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.envioConvite ? 
            sec.usuarioConvite ? `${toDateTime(sec.envioConvite)} por ${sec.usuarioConvite}` 
              : `${toDateTime(sec.envioConvite)}` : `Sem Convite Enviado`;
  }

  // VERIFICAR SE O CAMPO PRECISA ESTAR HABILITADO OU DESABILITADO
  habilitarEdicaoResponsavel = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.idResponsavelSecretaria ? true : false;
  };

  habilitarEnvioEmailIndividual = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.nmEmail ? true : false;
  }

  habilitarRemocaoSecretaria = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.idResponsavelSecretaria || sec.comEscolasInscritas ? false : true;
  }

  habilitarRelatorio = id => {
    const contexts = this.props.contexts;
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.idResponsavelSecretaria && sec.comEscolasInscritas && isAfterPeriodoRegistroParticipacao(contexts) && isAfterDataProva(contexts) ? true : false;
  };

  habilitarVisaoDashboard = id => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    return sec.idResponsavelSecretaria ? true : false;
  };

  // VER DASHBOARD COMO SECRETARIA
  handleViewDashboard = (id) => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    this.props.history.push({
      pathname: `/admin/inicio`,
      state: {
        responsavel: sec.idResponsavelSecretaria
      }
    });
  }

  // ENVIO INDEPENDENTE DE CONVITE PARA A SECRETARIA
  handleSendInvitation = (id) => {
    const secretary = this.state.secretaries.filter(s => s.cdSecretariaEducacao === id);
    this.props.history.push({
      pathname: `/admin/convite`,
      state: {
        invite: {
          to: patternSendToSecretary(secretary),
        },
        target: 1,
        history: { 
          path: `/admin/secretaria`,
          state: this.state,
        }
      }
    });
  }

  // VER RELATÓRIO DE SECRETARIA
  handleViewReport = (id) => {
    const sec = this.state.secretaries.find(s => s.cdSecretariaEducacao === id);
    this.props.history.push({
      pathname: '/admin/relatorio/secretaria', 
      state: { 
        responsavel: sec.idResponsavelSecretaria,
        history: { 
          path: `/admin/secretaria`,
          state: this.state,
        }
      }
    });
  }

  loadData = () => {
    let f = verifyPagingAndSorting(this.state);

    this.setState({ 
      filters: [...f], 
      filtered: true, 
      loading: true 
    });

    const parseFilters = queryString.parse(this.props.location.search);

    if (parseFilters.uf || parseFilters.city || parseFilters.adminDep || parseFilters.email) {

      if (parseFilters.city) this.SecretariaFilter.Endereco.findCities(parseFilters.uf, null);
      this.SecretariaFilter.Endereco.setState({
        filters:{
          ...this.SecretariaFilter.Endereco.state.filters,
          UF: parseFilters.uf,
          city: parseFilters.city ? parseFilters.city : '',
        }
      })

      this.SecretariaFilter.DependenciaAdministrativa.setState({
        filters:{
          adminDep: parseFilters.adminDep,
        }
      })

      this.SecretariaFilter.setState({
        filters: {
          ...this.SecretariaFilter.state.filters,
          email: parseFilters.email,
        }
      })

      if (parseFilters.email) f.push({ field: 'nmEmail', value: parseFilters.email })
      
      if (parseFilters.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: parseFilters.adminDep })

      if (parseFilters.uf) f.push({ field: 'cdUf', value: parseFilters.uf })
      if (parseFilters.city) f.push({ field: 'cdMunicipio', value: parseFilters.city })
    }      

    SecretariaService.filters(f)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            secretaries: [...res.data.content], 
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false,
          });
        } else {
          this.setState({ 
            secretaries: [], 
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
        this.setState({ loading: false });
      })    
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.SecretariaFilter ? this.SecretariaFilter.state ? this.SecretariaFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  render () {
    const pageName = 'Gerenciar Secretarias';
    const links = [
      {
        path: null,
        name: 'Secretarias',
      }
    ];
  
    const columns = [
      { label: '#', name: 'cdSecretariaEducacao', func: null, key: true },
      { label: 'Dep. Adm', name: 'nmDependenciaAdministrativa', func: patternAdminDep, key: false },
      { label: 'Nome da Secretaria', name: 'nmSecretariaEducacao', func: null, key: false },
      { label: 'Email da Secretaria', name: 'nmEmail', func: null, key: false },
      { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
      { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
      { label: 'Com Responsável pela Inscrição', name: 'idResponsavelSecretaria', func: this.comResponsavel, key: false, ordering: false },
      { label: 'Com Escolas Inscritas', name: 'comEscolasInscritas', func: this.comEscolasInscritas, key: false, ordering: false },
      { label: 'Evasão', name: 'evasao', func: evasaoLimiterMask, key: false, ordering: false },
      { label: 'Convite Enviado Em', name: 'cdSecretariaEducacao', func: this.verificarEnvioConvite, key: false, ordering: false },
    ];

    const actions = [
      { 
        name: this.labelEditar, 
        func: this.handleEditClick, 
        icon: <EditOutlinedIcon />, 
        disabled: this.disabledEditar,
      },
      { 
        name: this.labelResponsavel, 
        func: this.handleEditResponsibleClick, 
        icon: <SupervisorAccountIcon />, 
        disabled: this.disabledResponsavel,
      },
      {
        name: this.labelEnviarEmailIndividual, 
        func: this.handleSendInvitation, 
        icon: <MailOutlineIcon />, 
        disabled: this.disabledEnviarEmailIndividual,
      },
      { 
        name: this.labelEmailsEnviados, 
        func: this.handleViewSends, 
        icon: <AllInboxIcon />, 
        disabled: this.disabledEmailsEnviados,
      },
      { 
        name: this.labelRelatorio,
        func: this.handleViewReport,
        icon: <DescriptionIcon />,
        disabled: this.disabledRelatorio,
      },
      { 
        name: this.labelDashboard, 
        func: this.handleViewDashboard, 
        icon: <LaptopMacIcon />, 
        disabled: this.disabledDashboard,
      },
      { 
        name: this.labelApagar, 
        func: this.openDialogRemove,
        icon: <DeleteOutlinedIcon />, 
        disabled: this.disabledApagar,
        color: "secondary",
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SecretariaFilter
              parentRef={this.setSecretariaFilter}
              handleFilterChange={this.filterData}
            />
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__SECRETARIA_ADICIONAR]}>
                <ButtonAdd 
                  to='/admin/secretaria/adicionar'
                  title="Adicionar Secretaria"
                />
              </AuthorizedElement>
              <AuthorizedElement roles={[__SECRETARIA_EXPORTAR]}>
                <ButtonExport 
                  title="Exportar Planilha"
                  onClick={this.handleExportPlan} 
                />
              </AuthorizedElement>
            </Grid>
            <Table              
              columns={columns}
              tableData={this.state.secretaries}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleSort={this.handleSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        <Dialog 
          parentRef={this.setDialog}
          handleConfirm={this.handleRemoveClick}
        />
      </>
    )
  }
}

const mapStateToProps = state => ( { contexts: state.contexts } );
const mapDispatchToProps = dispatch => ( bindActionCreators({ loadContext }, dispatch) );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Secretaria));