import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosDependenciasAdministrativas extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            dependencias: [],
        }
    }

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosPrevistos, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantes, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    render () {
        const columns = [
            { label: 'Dependência Administrativa', name: 'nmDependenciaAdministrativa', func: null, key: false, width: '40', },
            { label: 'Alunos Inscritos', name: 'qtdAlunosPrevistos', func: null, key: false, width: '20', },
            { label: 'Alunos Participantes', name: 'qtdAlunosParticipantes', func: null, key: false, width: '20', },
            { label: 'Evasão', name: 'evasao', func: evasaoLimiterMask, key: false, width: '20', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", witdh: '40', },
            { column: 1, value: this.sumAlunosPrevistos(), width: '20', },
            { column: 2, value: this.sumAlunosParticipantes(), width: '20', },
            { column: 3, value: evasaoLimiterMask(this.calcEvasao()), width: '20', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom >Lista de Dependências Administrativas</Typography>
                    <Table              
                        columns={columns}
                        tableData={this.state.dependencias}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                        orderBy={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosDependenciasAdministrativas;