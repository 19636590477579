import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Typography } from '@material-ui/core';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __SECRETARIA_PEDIDO_EDITAR_ALERTAS } from '../../security/RoleConfiguration';

import { LinkTargetBlank } from '../../components/Link/Link';

const TextEmail = props => {
    const {qntEmail} = props;
    return qntEmail === 1 ? `Existe ${qntEmail} Secretaria de Educação cadastrada com este e-mail. `
        : `Existem ${qntEmail} Secretarias de Educação cadastradas com este e-mail. `;
}

const LinkEmail = props => {
    const {email} = props;
    const link = `/admin/secretaria?email=${email}`;
    return (
        <LinkTargetBlank link={link} className="font-warning">
            {props.children}
        </LinkTargetBlank>
    )
}

const TextDepAdmLocalidade = props => {
    const {qntAdmDep} = props;
    return qntAdmDep === 1 ? `Existe ${qntAdmDep} Secretaria de Educação cadastrada com mesma Dependência Administrativa e Localidade. `
        : `Existem ${qntAdmDep} Secretarias de Educação cadastradas com as mesmas Dependência Administrativa e Localidade. `;
}

const LinkDepAdmLocalidade = props => {
    const {depAdm, endereco} = props;

    let link = `/admin/secretaria`;
    link += depAdm === "MUNICIPAL" ? `?adminDep=${depAdm}&uf=${endereco.UF}&city=${endereco.city}` 
            : depAdm === "ESTADUAL" ? `?adminDep=${depAdm}&uf=${endereco.UF}` : ``;
    
    return (
        <LinkTargetBlank link={link} className="font-warning">
            {props.children}
        </LinkTargetBlank>
    )
}

export class AlertsForm extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            qntEmail: 0,
            qntAdmDep: 0,
            email: '',
            depAdm: '',
            endereco: {
                city: '',
                UF: '',
            },
        }
    }

    render () {    
        return (
            (this.state.qntEmail > 0 || this.state.qntAdmDep > 0) &&
                <AuthorizedElement roles={[__SECRETARIA_PEDIDO_EDITAR_ALERTAS]}>
                    <Grid item container spacing={3}>
                        <Grid item sm={12} lg={12}>
                            <Alert severity="warning">
                                <AlertTitle>Atenção</AlertTitle>
                                { this.state.qntEmail > 0 &&
                                    <Typography variant="body2">
                                        <TextEmail qntEmail={this.state.qntEmail}/>
                                        <LinkEmail email={this.state.email}>
                                            CLIQUE AQUI
                                        </LinkEmail> para Visualizar em outra aba.
                                    </Typography>
                                }
                                { this.state.qntAdmDep > 0 &&
                                    <Typography variant="body2"> 
                                        <TextDepAdmLocalidade qntAdmDep={this.state.qntAdmDep} />
                                        <LinkDepAdmLocalidade depAdm={this.state.depAdm} endereco={this.state.endereco}>
                                            CLIQUE AQUI
                                        </LinkDepAdmLocalidade> para Visualizar em outra aba.
                                    </Typography>
                                }
                            </Alert>
                        </Grid>
                    </Grid>
                </AuthorizedElement>
        )
    }
}

export default AlertsForm;