import Form from '../../views/Secretaria/Form';
import FormResponsavel from '../../views/Responsavel/Form';
import FormPedido from '../../views/Pedido/Form';
import FormMail from '../../views/Email/Form';
import Email from '../../views/Email/Email';
import {
    __SECRETARIA_ADICIONAR,
    __SECRETARIA_EDITAR,
    __SECRETARIA_EMAIL,
    __SECRETARIA_EMAIL_VISUALIZAR,
    __SECRETARIA_RESPONSAVEL_EDITAR,
    __SECRETARIA_PEDIDO_EDITAR,
    __SECRETARIA_PEDIDO_EMAIL,
    __SECRETARIA_PEDIDO_EMAIL_VISUALIZAR
} from '../../security/RoleConfiguration';

export const path = '/admin/secretaria';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __SECRETARIA_ADICIONAR ],
    },
    {
        path: path + '/editar/:id',
        component: Form,
        roles: [ __SECRETARIA_EDITAR ],
    },
    {
        path: path + '/:id/emails',
        component: Email,
        roles: [ __SECRETARIA_EMAIL ],
    },
    {
        path: path + '/:id/emails/:email',
        component: FormMail,
        roles: [ __SECRETARIA_EMAIL_VISUALIZAR ],
    },
    {
        path: path + '/responsavel/editar/:id',
        component: FormResponsavel,
        roles: [ __SECRETARIA_RESPONSAVEL_EDITAR ],
    },
    {
        path: path + '/pedido/editar/:id',
        component: FormPedido,
        roles: [ __SECRETARIA_PEDIDO_EDITAR ],
    },
    {
        path: path + '/pedido/:id/emails',
        component: Email,
        roles: [ __SECRETARIA_PEDIDO_EMAIL ],
    },
    {
        path: path + '/pedido/:id/emails/:email',
        component: FormMail,
        roles: [ __SECRETARIA_PEDIDO_EMAIL_VISUALIZAR ],
    }
];

export default listRoutes;