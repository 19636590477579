import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ListIcon from '@material-ui/icons/List';
import Grid from '@material-ui/core/Grid';

import ContextoService from '../../services/Contexto';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import { ButtonPrimary } from "../../components/Button/Button";
import { ContextDispatcher } from '../../components/Dispatcher/Dispatcher';
import Loading from '../../components/Loading/Loading';

import { makeStyles } from '@material-ui/core/styles';

import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import { patternContext } from '../../variables/Enums/Contexto';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import { 
    __CONTEXTO_EDITAR_VALOR,
    __CONTEXTO_ADICIONAR,
    __CONTEXTO_VISUALIZAR,
} from '../../security/RoleConfiguration';

import { 
    isBlank, 
    isBlankHelperText, 
    isNumber,
    isDateTimeHelperText,
    isDateTimeInvalid
} from '../../helper/ValidationHelper';

import { formatInTimeZone } from '../../helper/DateHelper';

export const useStyles = makeStyles( theme => ({
    body: {
        margin: theme.spacing(3, 0),
    },
}));

const ButtonList = ()  => {
    const classes = useStyles();
    return (
        <Grid item sm={12} lg={2} className={classes.body}>
            <ButtonPrimary
                to='/admin/contexto/listagem'
                component={Link}
                startIcon={<ListIcon />}
                name="Listagem de Variáveis"
            />
        </Grid>
    )
}

class Contexto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contexts: [],
            errors: {},
            helpers: {},
            loading: true,
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                    if (c.name === e.target.name) 
                        if ( c.type !== "LONG" || ( c.type === "LONG" && !isNumber(e.target.value)) ) 
                            c.value = e.target.value;
                    return true
                })
            ],
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    // INPUTS DATE TIME PICKER
    handleChangeDateTime = (e, name, key) => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                    if (c.name === name) c.value = e;
                    return true
                })
            ],
            errors: {...this.state.errors, [name]: false },
            helpers: {...this.state.helpers, [name]: null }
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // MONTAR VARIAVEIS
    fieldsContext = () => {
        if (this.state.contexts.length > 0) {
            return (
                <Grid container spacing={3} style={{ marginTop: 8, marginBottom: 8 }} >
                    {this.state.contexts.map((c, key) => {
                        return (
                            <ContextDispatcher 
                                context={c}
                                key={key}
                                required
                                label={c.label}
                                name={c.name}
                                autoComplete="off"
                                onChange={(e) => {
                                    c.type === "DATA" ? this.handleChangeDateTime(e, c.name, key) : this.handleChange(e)
                                    this.handleBlur(key)
                                }}
                                disabled={!AuthorizedFunction([__CONTEXTO_EDITAR_VALOR])}
                                value={this.state.contexts[key].value}
                                error={this.state.errors[c.name]}
                                helperText={this.state.helpers[c.name]}
                            />
                        )
                    })}
                </Grid>
            )
        }
    }

    // VALIDAÇÃO
    isValid = c => {
        if (isBlank(c.value)) {
            this.setState({ 
                errors: {...this.state.errors, [c.name]: true },
                helpers: {...this.state.helpers, [c.name]: isBlankHelperText() } 
            })
            return false
        } else if (isDateTimeInvalid(c.value)) {
            this.setState({ 
                errors: {...this.state.errors, [c.name]: true },
                helpers: {...this.state.helpers, [c.name]: isDateTimeHelperText() } 
            })
            return false
        }
        return true
    }

    // SALVAR CONTEXTO
    handleBlur = (key) => {
        const c = this.state.contexts.find((context, index) => index === key);

        if (!this.isValid(c)) return

        const context = { 
            nmTipo: c.type,
            nmValor: formatInTimeZone(c.value),
            nmVariavelContexto: c.label 
        }       

        ContextoService.edit(c.id, context)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Contexto Salvo com Sucesso",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }
    
    componentDidMount() {
        let f = [];
        f.push({ field: 'pageable', value: false });

        ContextoService.filters(f)
            .then(res => {
                if(res.data.content) {
                    const contexts = patternContext(res.data.content);

                    let errors = {};
                    contexts.forEach(context => { errors[context.name] = false });

                    let helpers = {};
                    contexts.forEach(context => { helpers[context.name] = null });

                    this.setState({
                        contexts: [...this.state.contexts, ...contexts],
                        errors: errors,
                        helpers: helpers,
                        loading: false,
                    });
                } else {
                    this.setState({
                        contexts: [],
                        errors: {},
                        helpers: {},
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                
                this.setState({ loading: false, });
            })
    }

    render() {
        const page = 'Contexto';
        const links = [];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>
                <Title>{page}</Title>

                <Grid container spacing={2}>
                    <AuthorizedElement roles={[__CONTEXTO_VISUALIZAR]}>
                        <ButtonList/>
                    </AuthorizedElement>
                    <AuthorizedElement roles={[__CONTEXTO_ADICIONAR]}>
                        <ButtonAdd 
                            to="/admin/contexto/adicionar"
                            title="Adicionar Variável"
                        />
                    </AuthorizedElement>
                </Grid>

                { this.state.loading ? <Loading /> : this.fieldsContext() }
            </>
        )
    }
}

export default Contexto;