import Escola from '../../views/Recibo/Escola';
import Secretaria from '../../views/Recibo/Secretaria';
import {
    __RECIBO_ESCOLA,
    __RECIBO_SECRETARIA,
} from '../../security/RoleConfiguration';

export const path = '/admin/recibo';

export const listRoutes = [
    {
        path: path + '/escola',
        component: Escola,
        roles: [ __RECIBO_ESCOLA ],
    },
    {
        path: path + '/secretaria',
        component: Secretaria,
        roles: [ __RECIBO_SECRETARIA ],
    },
];

export default listRoutes;