import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import RemoveCircleOutlineSharpIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';

import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { LinkRegular } from '../../components/Link/Link';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import { ButtonSecondary } from '../../components/Button/Button';
import Dialog from '../../forms/Dialog/DialogRemove';
import InscricaoFilter from '../../forms/Inscricao/inscricaoFilter';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import { 
  __INSCRICAO_REMOVER,
  __INSCRICAO_ADICIONAR_ESCOLA,
} from '../../security/RoleConfiguration';

import { numberMask } from '../../helper/MaskHelper';

import { locationResponsavelParse, verifyLocationResponsavelParse } from '../../helper/ParseStateHelper';
import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdMecEscola',
      defaultOrderBy: 'cdMecEscola',
      filtered: false,
      filters: [],
      schools: [],
      loading: true,
      responsavel: null,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDialog = t => this.Dialog = t;
  setInscricaoFilter = f => this.InscricaoFilter = f;

  // LABELS
  labelExcluir = id => !this.disabledExcluir(id) ? "Remover Seleção e Alunos Inscritos" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledExcluir = id => !AuthorizedFunction([__INSCRICAO_REMOVER]);

  // ICON SAVED
  labelSaved = id => this.enabledSavedChanges(id) ? "Sem Inscrição de Alunos Realizada" : "Alunos Inscritos Salvos";
  enabledSavedChanges = id => this.state.schools.find(s => s.cdMecEscola === id).qtdAlunosPrevistos === null;

  loadData = () => {
    let filters = verifyPagingAndSorting(this.state);

    const f = locationResponsavelParse(this.props.location, filters);

    this.setState({ 
      filters: [...filters], 
      filtered: true, 
      loading: true,
      responsavel: f.find(f => f.field === 'id') ? f.find(f => f.field === 'id').value : null,
    });

    InscricaoService.filterEscolas(f)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            ...this.state,
            schools: [...res.data.content], 
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false 
          });
        } else {
          this.setState({ 
            ...this.state,
            schools: [], 
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false 
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
        
        this.setState({ loading: false });
      })
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.InscricaoFilter ? this.InscricaoFilter.state ? this.InscricaoFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  // Função para a inserção dos valores no botão de qtd. Alunos Previstos
  handleChange = (e, id) => {
    this.setState({
      schools: [...this.state.schools.filter(s => {
        if (s.cdMecEscola === id) s.qtdAlunosPrevistos = numberMask(e.target.value);
        return true
      })],
    });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  openDialogRemove = id => {
    const school = this.state.schools.find(school => school.cdMecEscola === id);
    this.Dialog.setState({ 
      dialog: { 
        open: true, 
        title: `Deseja Remover a Inscrição para o(a) ${school.nmMecEscola} ?`,
        text: `Ao remover a inscrição a seleção será removida, a quantidade de alunos será zerada, e a escola estará disponível novamente para outra secretaria realizar sua inscrição.`,
        id: id,
        loading: false,
      }
    });
  }

  // Função para remoção do registro da tabela de inscrição
  handleRemoveClick = (id) => {
    this.Dialog.loading();

    const inscricao = this.state.schools.find(s => s.cdMecEscola === id);
    InscricaoService.remove(inscricao.cdMecEscola)
      .then(res => {
        this.setState({
          schools: [...this.state.schools.filter(s => {
            if (s.cdMecEscola === id) s.qtdAlunosPrevistos = null;
            return true
          })],
        });

        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: "A inscrição foi excluída com sucesso.",
            type: 'success',
            open: true
          }
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
  }

  handleBlur = (e, id) => {
    const inscricao = this.state.schools.find(s => s.cdMecEscola === id);

    if (inscricao.qtdAlunosPrevistos > 0) {
      InscricaoService.add(this.state.responsavel, inscricao)
        .then(res => {
          this.Toast.setState({
            message: {
              message: "Inscrição Efetuada com sucesso.",
              type: "success",
              open: true
            }
          });
        })
        .catch(error => {
          this.Toast.setState({
            message: {
                message: "Não foi possível completar a inscrição.",
                type: 'error',
                open: true
            }
          })
        });
    }
  } 

  insertSchool = () => {
    return (
      <LinkRegular to={{ 
        pathname: `/admin/escola/adicionar`, 
        state: { 
          userType: 'SECRETARIA', 
          responsavel: this.state.responsavel, 
          history: {
            path: `/admin/inscricao/cadastrar`,
            state: this.state,
          }
        }
      }} className="font-info"> CLIQUE AQUI </LinkRegular>
    )
  }

  render() {

    const pageName = 'Selecionar Escolas e informar a quantidade de Alunos Inscritos';
    const links = [
      {
        path: !verifyLocationResponsavelParse(this.props.location) ? '/admin/inicio' : null,
        name: 'Início'
      }
    ];

    const columns = [
      { label: 'INEP', name: 'cdMecEscola', func: null, key: true },
      { label: 'Escola', name: 'nmMecEscola', func: null, key: false },
      { label: 'Endereço', name: 'nmEndereco', func: null, key: false },
      { label: 'Qtd. Alunos', name: 'qtdAlunosPrevistos', func: null, blur: this.handleBlur, change: this.handleChange, key: false, item: 'textBox' },
    ];

    const actions = [
      { 
        name: this.labelSaved,
        func: () => true,
        icon: <DoneIcon />, 
        disabled: this.enabledSavedChanges,
      },
      { 
        name: this.labelExcluir, 
        func: this.openDialogRemove,
        icon: <RemoveCircleOutlineSharpIcon />, 
        disabled: this.disabledExcluir,
        color: "secondary",
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid item container spacing={3}>
          <Grid item sm={12} lg={12}>
            <Alert severity="warning">
              <Typography variant="body2" style={{ display: "flex" }}>
                A seleção da escola será feita no momento em que for informada a quantidade de alunos inscritos e poderá ser desfeita no icone 
                <RemoveCircleOutlineSharpIcon color="secondary" style={{ marginTop: "-2px", marginLeft: "4px" }} />.
                Ao desfazer a seleção o vínculo será removido, a quantidade de alunos será zerada, e a escola estará disponível novamente para outra secretaria realizar sua inscrição.
              </Typography>
            </Alert>
          </Grid>
        </Grid>

        <AuthorizedElement roles={[ __INSCRICAO_ADICIONAR_ESCOLA ]}>
          <Grid item container spacing={3}>
            <Grid item sm={12} lg={12}>
              <Alert severity="info">
                <Typography variant="body2">
                  Se não encontrou a escola na lista abaixo, clique no botão {this.insertSchool()} para cadastrar uma nova Escola.
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        </AuthorizedElement>

        <Grid item container spacing={3}>
          <Grid item sm={12} lg={12}>
            <InscricaoFilter 
              parentRef={this.setInscricaoFilter}
              handleFilterChange={this.filterData}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.schools}
              actions={actions}
              page={this.state.page}           
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}              
              order={null}
              orderBy={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={() => this.props.history.push({ pathname: `/admin/inicio`, state: { responsavel: this.state.responsavel }})}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>

        <Dialog 
          parentRef={this.setDialog}
          handleConfirm={this.handleRemoveClick}
        />
      </>
    )
  }
}

export default withRouter(List);