import API from './API';

const path = `mensagens`;

const MessagemService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // EDITAR
    edit: async (id, message) => await API.edit(path, id, message),
}

export default MessagemService;