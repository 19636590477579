import Escola from '../../views/Relatorio/Escola';
import Secretaria from '../../views/Relatorio/Secretaria';
import {
    __RELATORIO_ESCOLA_VISUALIZAR,
    __RELATORIO_SECRETARIA_VISUALIZAR,
} from '../../security/RoleConfiguration';

export const path = '/admin/relatorio';

export const listRoutes = [
    {
        path: path + '/escola',
        component: Escola,
        roles: [ __RELATORIO_ESCOLA_VISUALIZAR ],
    },
    {
        path: path + '/secretaria',
        component: Secretaria,
        roles: [ __RELATORIO_SECRETARIA_VISUALIZAR ],
    },
];

export default listRoutes;