export const cpfMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

export const zipCodeMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
}

export const phoneNumberMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4,5})(\d{4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
}

export const phoneDDDMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})\d+?$/, '$1')
}

export const mecCodeMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{8})\d+?$/, '$1')
}

export const evasaoLimiterMask = value => {
    return (Math.round(value * 100) / 100).toFixed(2) + "%";
}

export const numberMask = value => {
    return value
      .replace(/\D+/g, '')
}