import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';

import { removePagingAndSorting } from '../../helper/PaginationHelper';

import { EscolaFilterFields as Escola } from '../Escola/EscolaFilterFields';

export class InscricaoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            count: 0,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEscola = form => this.Escola = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.Escola.setState({
            filters: {
                nameSchool: filters.find(f =>  f.field === 'nmEscola') ? filters.find(f =>  f.field === 'nmEscola').value : "",
                inepSchool: filters.find(f =>  f.field === 'cdMecEscola') ? filters.find(f =>  f.field === 'cdMecEscola').value : "",
            }
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const filtersEscola = this.Escola.state.filters;

        if (filtersEscola.nameSchool) f.push({ field: 'nmEscola', value: filtersEscola.nameSchool })
        if (filtersEscola.inepSchool) f.push({ field: 'cdMecEscola', value: filtersEscola.inepSchool })

        this.props.handleFilterChange(f);     
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            count: 0,
        })

        this.Escola.setState({
            filters: {
                nameSchool: '',
                inepSchool: '',  
            }
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Escola
                        parentRef={this.setEscola}
                        disabledEmail={true}
                    />
                </Grid>
            </Filters>
        )
    }
}

export default InscricaoFilter;