import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import PedidoService from '../../services/Pedido';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import SelectOption from '../../components/Inputs/Select/Select';
import Loading from '../../components/Loading/Loading';

import AlertsForm from './AlertsForm';

import Endereco from '../../forms/Endereco/Endereco';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativa';
import Solicitante from '../../forms/Pedido/Solicitante';

import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';
import { optionsAvaliacao } from '../../variables/Enums/Secretaria';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __SECRETARIA_PEDIDO_EDITAR } from '../../security/RoleConfiguration';

import { 
    isBlank,
    isBlankHelperText,
    isMailInvalid,
    isMailHelperText,
    isEmailsDiffHelperText,
} from '../../helper/ValidationHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secretary: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                name: '',
                email: '',
                emailRepeat: '',
                emailOriginal: '',
                status: '',
                statusOriginal: '',
                justificativa: '',
            },
            errors: {
                name: false,
                email: false,
                emailRepeat: false,
                status: false,
                justificativa: false,
            },
            helpers: {
                name: null,
                email: null,
                emailRepeat: null,
                status: null,
                justificativa: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: { 
                path: null, 
                state: null
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            secretary: {...this.state.secretary, [e.target.name]: e.target.value },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setSolicitante = f => this.Solicitante = f;
    setTelefone = f => this.Telefone = f;
    setEndereco = f => this.Endereco = f;
    setDependenciaAdministrativa = f => this.DependenciaAdministrativa = f;
    setAlertsForm = f => this.AlertsForm = f;

    // LIMPAR JUSTIFICATIVA NA TOCA PARA O STATUS "EM ANALISE"
    clearJustificativa = e => {
        if (e.target.value === "EM_ANALISE") {
            this.setState({
                secretary: {
                    ...this.state.secretary,
                    status: e.target.value,
                    justificativa: '',
                }
            })
        }
    }

    // VALIDAÇÕES
    isValid = () => {
        if (this.state.secretary.id && 
            (isBlank(this.state.secretary.name) || isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) || 
                (this.state.secretary.email !== this.state.secretary.emailOriginal && (isBlank(this.state.secretary.emailRepeat) || isMailInvalid(this.state.secretary.emailRepeat))) || 
                isBlank(this.state.secretary.status) || (isBlank(this.state.secretary.justificativa) && (this.state.secretary.status === "RECUSADO" || this.state.secretary.status === "APROVADO")))) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.secretary.name) ? true : false,
                    email: isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) ? true : false,
                    emailRepeat: this.state.secretary.email !== this.state.secretary.emailOriginal ?
                                    isBlank(this.state.secretary.emailRepeat) || isMailInvalid(this.state.secretary.emailRepeat) ? true : false : false,
                    status: isBlank(this.state.secretary.status) ? true : false,
                    justificativa: isBlank(this.state.secretary.justificativa) 
                                    && (this.state.secretary.status === "RECUSADO" || this.state.secretary.status === "APROVADO") ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.secretary.name) ? isBlankHelperText() : null,
                    email: isBlank(this.state.secretary.email) ? isBlankHelperText() : isMailInvalid(this.state.secretary.email) ? isMailHelperText() : null,
                    emailRepeat: this.state.secretary.email !== this.state.secretary.emailOriginal ?
                                    isBlank(this.state.secretary.emailRepeat) ? isBlankHelperText() : isMailInvalid(this.state.secretary.emailRepeat) ? isMailHelperText() : null : null,
                    status: isBlank(this.state.secretary.status) ? isBlankHelperText() : false,
                    justificativa: isBlank(this.state.secretary.justificativa) 
                                    && (this.state.secretary.status === "RECUSADO" || this.state.secretary.status === "APROVADO") ? isBlankHelperText() : null,
                }
            });
            return false;

        } else if (this.state.secretary.email !== this.state.secretary.emailOriginal && this.state.secretary.email !== this.state.secretary.emailRepeat) {
            this.setState({ 
                errors: {
                    email: true,
                    emailRepeat: true,
                },
                helpers: {
                    emailRepeat: isEmailsDiffHelperText(),
                },
            });
            return false;

        } else if (isBlank(this.state.secretary.name) || isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) ||
                    (this.state.secretary.email !== this.state.secretary.emailOriginal && (isBlank(this.state.secretary.emailRepeat) || isMailInvalid(this.state.secretary.emailRepeat)))) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.secretary.name) ? true : false,
                    email: isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) ? true : false,
                    emailRepeat: this.state.secretary.email !== this.state.secretary.emailOriginal ?
                                    isBlank(this.state.secretary.emailRepeat) || isMailInvalid(this.state.secretary.emailRepeat) ? true : false : false,
                },
                helpers: {
                    name: isBlank(this.state.secretary.name) ? isBlankHelperText()  : null,
                    email: isBlank(this.state.secretary.email) ? isBlankHelperText() : isMailInvalid(this.state.secretary.email) ? isMailHelperText() : null,
                    emailRepeat: this.state.secretary.email !== this.state.secretary.emailOriginal ?
                                    isBlank(this.state.secretary.emailRepeat) ? isBlankHelperText() : isMailInvalid(this.state.secretary.emailRepeat) ? isMailHelperText() : null : null,
                },
            });                
            return false;
        }            
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid()
        const formEnderecoIsValid = this.Endereco.isValid();
        const formAdmDepIsValid = this.DependenciaAdministrativa.isValid();
        const formSolicitanteIsValid = this.Solicitante.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();

        if (!formIsValid || !formEnderecoIsValid || !formAdmDepIsValid || !formSolicitanteIsValid || !formTelefoneIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const secretary = {
            nmSecretariaEducacaoTemporaria: this.state.secretary.name,
            nmEmail: this.state.secretary.email,

            nmCep: this.Endereco.state.address.zipCode,
            nmEndereco: this.Endereco.state.address.address,
            nmNumero: this.Endereco.state.address.number,
            nmComplemento: this.Endereco.state.address.complement,
            nmBairro: this.Endereco.state.address.district,
            cdMunicipioRegiao: this.Endereco.state.address.city,

            nmDependenciaAdministrativa: this.DependenciaAdministrativa.state.adminDep,

            telefones: [...this.Telefone.state.phones],

            nmSolicitante: this.Solicitante.state.solicitor.name,
            nmCpfSolicitante: this.Solicitante.state.solicitor.cpf,
            nmCargoSolicitante: this.Solicitante.state.solicitor.cargo,

            nmStatusAvaliacao: this.state.secretary.id ? this.state.secretary.status : "EM_ANALISE",
            nmJustificativa: this.state.secretary.justificativa,
        }

        let msgSuccess = "";
        let request = "";
        let timeout = "";

        if (this.state.secretary.id) {
            request = PedidoService.edit(this.state.secretary.id, secretary);
            msgSuccess = "O Pedido de Secretaria foi Atualizado com Sucesso.";
            timeout = 2000;
        } else {
            msgSuccess = "O Pedido de Secretaria foi Realizado com Sucesso. O Resultado será informado por email assim que Disponível.";
            request = PedidoService.add(secretary);
            timeout = 5000;
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), timeout);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    componentDidMount() {
        this.filterPersistence(this.props.location);

        if (this.state.secretary.id) {
            PedidoService.find(this.state.secretary.id)
                .then(res => {
                    this.setState({
                        secretary: {
                            id: res.data.cdSecretariaEducacaoPedido,
                            name: res.data.secretariaTemporaria.nmSecretariaEducacaoTemporaria,
                            email: res.data.secretariaTemporaria.nmEmail,
                            emailOriginal: res.data.secretariaTemporaria.nmEmail,
                            emailRepeat: '',
                            status: res.data.nmStatusAvaliacao,
                            statusOriginal: res.data.nmStatusAvaliacao,
                            justificativa: res.data.nmJustificativa,
                        },
                        loading: false,
                    });

                    this.Endereco.findCities(res.data.secretariaTemporaria.municipioRegiao.uf.cdUf, res.data.secretariaTemporaria.municipioRegiao.cdMunicipioRegiao);
                    this.Endereco.setState({
                        address: {
                            zipCode: res.data.secretariaTemporaria.nmCep,
                            address: res.data.secretariaTemporaria.nmEndereco,
                            number: res.data.secretariaTemporaria.nmNumero,
                            complement: res.data.secretariaTemporaria.nmComplemento,
                            district: res.data.secretariaTemporaria.nmBairro,
                            city: res.data.secretariaTemporaria.municipioRegiao.cdMunicipioRegiao,
                            UF: res.data.secretariaTemporaria.municipioRegiao.uf.cdUf,
                        }
                    })

                    this.DependenciaAdministrativa.setState({
                        adminDep: res.data.secretariaTemporaria.nmDependenciaAdministrativa,
                    })

                    this.Solicitante.setState({
                        solicitor: {
                            name: res.data.nmSolicitante,
                            cpf: res.data.nmCpfSolicitante,
                            cargo: res.data.nmCargoSolicitante,
                        }
                    })

                    this.Telefone.setState({ 
                        phones: [...patternPhones(res.data.secretariaTemporaria.telefones)],
                    })

                    this.AlertsForm.setState({
                        qntEmail: res.data.qntCadastroEmail,
                        qntAdmDep: res.data.qntCadastroDepAdmLocalidade,
                        email: res.data.secretariaTemporaria.nmEmail,
                        depAdm: res.data.secretariaTemporaria.nmDependenciaAdministrativa,
                        endereco: {
                            city: res.data.secretariaTemporaria.municipioRegiao.cdMunicipioRegiao,
                            UF: res.data.secretariaTemporaria.municipioRegiao.uf.cdUf,
                        }
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Secretaria para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    // VERIFICAR SE PEDIDO PODE ESTAR HABILITADO
    verifyStatus = () => this.state.secretary.status !== "EM_ANALISE" && this.state.secretary.statusOriginal !== "EM_ANALISE";

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path ?? `/`}`,
            state: { 
                history: {
                    state: this.state.history.state
                }
            }
        });    
    }

    render () {
        const page = this.state.secretary.id ? 'Editar Pedido de Secretaria' : 'Adicionar Pedido de Secretaria';
        const links = [
            {
                path: null,
                name: 'Secretarias'
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />

                <AuthorizedElement roles={[__SECRETARIA_PEDIDO_EDITAR]}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs links={links} active={page} />
                        </Grid>
                    </Grid>
                </AuthorizedElement>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <AlertsForm parentRef={this.setAlertsForm} />

                            <Grid item container spacing={3}>
                                <Grid item sm={12} lg={5}>
                                    <Text
                                        required
                                        name="name"
                                        label="Nome da Secretaria"
                                        value={this.state.secretary.name}
                                        error={this.state.errors.name}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.name}
                                    />
                                </Grid>                        
                                <Grid item sm={12} lg={3}>
                                    <Text
                                        required
                                        name="email"
                                        label="Email da Secretaria"
                                        value={this.state.secretary.email}
                                        error={this.state.errors.email}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.email}
                                        onCut={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                    />
                                </Grid>
                                { (isBlank(this.state.secretary.id) || this.state.secretary.email !== this.state.secretary.emailOriginal) &&
                                    <Grid item sm={12} lg={3}>
                                        <Text
                                            required
                                            name="emailRepeat"
                                            label="Repetir Email da Secretaria"
                                            value={this.state.secretary.emailRepeat}
                                            error={this.state.errors.emailRepeat}
                                            onChange={this.handleChange}
                                            helperText={this.state.helpers.emailRepeat}
                                            onCut={(e) => e.preventDefault()}
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Endereco parentRef={this.setEndereco} />
                            <DependenciaAdministrativa parentRef={this.setDependenciaAdministrativa} withFederal={false} />
                        </Grid>
                        <Telefone parentRef={this.setTelefone} />
                        <Solicitante parentRef={this.setSolicitante} />

                        { AuthorizedFunction([__SECRETARIA_PEDIDO_EDITAR]) && this.state.secretary.id && 
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item container spacing={3}>
                                    <Grid item sm={12} lg={3}>
                                        <SelectOption
                                            required
                                            name="status"
                                            label="Status do Pedido"
                                            value={this.state.secretary.status}
                                            error={this.state.errors.status}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.clearJustificativa(e);
                                            }}
                                            options={optionsAvaliacao}
                                            hiddenblank="true"
                                            disabled={this.verifyStatus()}
                                            helperText={this.state.helpers.status}
                                        />
                                    </Grid>
                                    { (this.state.secretary.status === "RECUSADO" || this.state.secretary.status === "APROVADO") &&
                                        <Grid item sm={12} lg={9}>
                                            <Text
                                                required
                                                multiline={true}
                                                rows={4}
                                                name="justificativa"
                                                label="Justificativa"
                                                value={this.state.secretary.justificativa}
                                                error={this.state.errors.justificativa}
                                                onChange={this.handleChange}
                                                disabled={this.verifyStatus()}
                                                helperText={this.state.helpers.justificativa}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        } 

                        <ButtonsForm 
                            onClick={this.handleSubmit} 
                            onBack={this.state.history.path ? () => this.handleBack() : null} 
                            idFocus={`saveButton`} 
                            loading={this.state.loadingButtonSave} 
                        />
                    </>
                }
            </>
        )
    }
}

export default withRouter(Form);