export const optionsAdminDep = [
    { value: 'MUNICIPAL', label: 'Municipal' },
    { value: 'ESTADUAL', label: 'Estadual' },
    { value: 'FEDERAL', label: 'Federal' },
];

export const optionsAdminDepWithoutFederal = [
    { value: 'MUNICIPAL', label: 'Municipal' },
    { value: 'ESTADUAL', label: 'Estadual' },
]

export const patternAdminDep = dep => {
    return dep === "MUNICIPAL" ? "Municipal":
            dep === "ESTADUAL" ? "Estadual" :
            dep === "FEDERAL" ? "Federal" : "";
}