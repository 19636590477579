import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

export class SecretariaFilterFields extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
              name: '',
              email: '',            
            }
        }
    }
  
    handleChange = e => {
        this.setState({ 
            filters: {...this.state.filters, [e.target.name]: e.target.value, } 
        })
    }
  
    render () {
        return (
            <>
              <Grid item sm={12} lg={5}>
                <Text
                    label='Nome da Secretaria'
                    value={this.state.filters.name}
                    name='name'
                    onChange={this.handleChange}
                />
              </Grid>
              {!this.props.disabledEmail &&
                <Grid item sm={12} lg={4}>
                  <Text
                      label='Email da Secretaria'
                      value={this.state.filters.email}
                      name='email'
                      onChange={this.handleChange}
                  />
                </Grid>
              }
            </>
        )
    }
  }
  
  export default SecretariaFilterFields;