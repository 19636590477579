import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import { BoxerReports } from '../../components/Boxer/Boxer';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

export class DadosEscola extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            school: {
                nmDependenciaAdministrativaEscola: '',
                nmEscola: '',
                ufEscola: '',
            },
        }
    }

    render () {
        return (
            <BoxerReports category="Dados da Escola">
                <Typography variant="body2">
                    Dependência Administrativa: {patternAdminDep(this.state.school.nmDependenciaAdministrativaEscola)}
                </Typography>
                <Typography variant="body2">
                    UF: {this.state.school.ufEscola}
                </Typography>
                <Typography variant="body2">
                    Nome da Escola: {this.state.school.nmEscola}
                </Typography>
                <Typography variant="body2">
                    &nbsp;
                </Typography>
            </BoxerReports>
        )
    }
}

export default DadosEscola;