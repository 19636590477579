import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ButtonPrimary, ButtonDefault } from '../../components/Button/Button';
import useEnterKeyListener from "../../helper/FocusHelper";

// BOTÃO DE SALVAR
export const ButtonSave = props => {
    useEnterKeyListener({ querySelectorToExecuteClick: `#${props.idFocus ?? `__btn`}` })

    return (
        <ButtonPrimary
            startIcon={props.loading ? <CircularProgress size={16} /> : props.startIcon ?? <CheckOutlinedIcon />}
            style={{ margin: 4 }}
            onClick={props.onClick}
            name={props.name ?? "Salvar"}
            disabled={props.loading || props.saveDisabled ? true : false}
            id={props.idFocus ?? null}
        />
    )
}

// BOTÃO DE LIMPAR
export const ButtonClear = props => {
    return (
        <ButtonDefault
            startIcon={<ClearOutlinedIcon />}
            style={{ margin: 4 }}
            onClick={props.onClick}
            name={props.name ?? "Limpar"}
        />
    )
}

// BOTÃO DE CANCELAR
export const ButtonCancel = props => {
    return (
        <ButtonPrimary
            color="secondary"
            onClick={props.onBack ?? props.history.goBack}
            startIcon={<ClearOutlinedIcon />}
            style={{ margin: 4 }}
            name="Cancelar"
        />
    )
}

export const ButtonsForm = props => {
    return (
        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
            <Grid item sm={12} lg={12}>
                <ButtonSave {...props} />
                <ButtonCancel {...props} />
            </Grid>   
        </Grid>
    )
}

export default withRouter(ButtonsForm);