import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosEscolas extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            schools: [],
        }
    }

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosPrevistos, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosParticipantes, 0);

    // SOMA PARA ESCOLAS PARTICIPANTES
    sumEscolasParticipantes = () => this.state.schools.length;

    // CALCULAR EVASÃO
    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, witdh: '10', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, witdh: '15', },
            { label: 'Dep. Adm. ', name: 'nmDependenciaAdministrativa', func: null, key: false, witdh: '10', },
            { label: 'INEP', name: 'inep', func: null, key: true, witdh: '10', },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, width: '25', },
            { label: 'Alunos Inscritos', name: 'qtdAlunosPrevistos', func: null, key: false, width: '10', },
            { label: 'Alunos Participantes', name: 'qtdAlunosParticipantes', func: null, key: false, width: '10', },
            { label: 'Evasão', name: 'evasao', func: evasaoLimiterMask, key: false, width: '10', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", witdh: '10', },
            { column: 1, value: "-", width: '15', },
            { column: 2, value: "-", width: '10', },
            { column: 3, value: "-", width: '10', },
            { column: 4, value: this.sumEscolasParticipantes(), width: '25', },
            { column: 5, value: this.sumAlunosPrevistos(), width: '10', },
            { column: 6, value: this.sumAlunosParticipantes(), width: '10', },
            { column: 7, value: evasaoLimiterMask(this.calcEvasao()), width: '10', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom >Lista de Escolas</Typography>
                    <Table              
                        columns={columns}
                        tableData={this.state.schools}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                        orderBy={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosEscolas;