import API from './API';

const path = `responsaveis`;

const ResponsavelService = {
    // LISTAR UM
    find: async id => await API.find(path, id),

    // LISTAR UM
    add: async responsavel => await API.add(path, responsavel),

    // EDITAR
    edit: async (id, responsavel) => await API.edit(path, id, responsavel),
}

export default ResponsavelService;