import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

export class DadosEscolas extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            schools: []
        }
    }

    render () {
        const columns = [
            { label: 'INEP', name: 'inep', func: null, key: true },
            { label: 'Escola', name: 'nmEscola', func: null, key: false },
            { label: 'Alunos', name: 'qtdAlunosPrevistos', func: null, key: false },
        ];
        
        const actions = [];

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.schools}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                        orderBy={null}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default DadosEscolas;