import API from './API';
import { API as APIService } from './API';

const path = `secretarias`;

const SecretaryService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // EDITAR
    edit: async (id, secretary) => await API.edit(path, id, secretary),

    // ADICIONAR
    add: async secretary => await API.add(path, secretary),

    // REMOVER
    remove: async id => await API.remove(path, id),

    // CONFIRMAR ENVIO DE CONVITES
    inviteConfirm: async ids => await APIService.put(`${path}/confirmarConvites`, ids),

    // LISTAR EMAILS
    listMails: async id => await APIService.get(`${path}/${id}/emails`),
}

export default SecretaryService;