export const __ADMINISTRADOR = 'ADMINISTRADOR';
export const __CENTRAL = 'CENTRAL';
export const __ESCOLA = 'ESCOLA';
export const __SECRETARIA = 'SECRETARIA';

export const __ARQUIVO_DOWNLOAD = '__arquivo.download';
export const __ARQUIVO_REMOVER = '__arquivo.remover';
export const __ARQUIVO_ADICIONAR = '__arquivo.adicionar';
export const __ARQUIVO_VISUALIZAR = '__arquivo.visualizar';

export const __CONTEXTO_VISUALIZAR_VALOR = '__contexto.valor.visualizar';
export const __CONTEXTO_EDITAR_VALOR = '__contexto.valor.editar';
export const __CONTEXTO_EDITAR = '__contexto.editar';
export const __CONTEXTO_REMOVER = '__contexto.remover';
export const __CONTEXTO_ADICIONAR = '__contexto.adicionar';
export const __CONTEXTO_VISUALIZAR = '__contexto.visualizar';

export const __CONVITE_EMAIL_REMOVER = '__convite.email.remover';
export const __CONVITE_EMAIL_EDITAR = '__convite.email.editar';
export const __CONVITE_ENVIAR = '__convite.enviar';
export const __CONVITE_VISUALIZAR = '__convite.visualizar';

export const __ESCOLA_VISUALIZAR = '__escola.visualizar';
export const __ESCOLA_EDITAR = '__escola.editar';
export const __ESCOLA_ADICIONAR = '__escola.adicionar';
export const __ESCOLA_ADICIONAR_OPCOES_AVANCADAS = '__escola.adicionar.opcoes-avancadas';
export const __ESCOLA_EXPORTAR = '__escola.exportar';
export const __ESCOLA_EMAIL_ENVIAR_INDIVIDUAL = '__escola.email.enviar.individual';
export const __ESCOLA_EMAIL_ENVIAR_MASSA = '__escola.email.enviar.massa';
export const __ESCOLA_DASHBOARD = '__escola.dashboard';
export const __ESCOLA_EMAIL = '__escola.email';
export const __ESCOLA_EMAIL_REENVIAR = '__escola.email.reenviar';
export const __ESCOLA_EMAIL_VISUALIZAR = '__escola.email.visualizar';
export const __ESCOLA_RESPONSAVEL_EDITAR = '__escola.responsavel.editar';

export const __INSCRICAO_REMOVER = '__inscricao.remover';
export const __INSCRICAO_EDITAR = '__inscricao.editar';
export const __INSCRICAO_ADICIONAR = '__inscricao.adicionar';
export const __INSCRICAO_ADICIONAR_ESCOLA = '__inscricao.adicionar.escola';

export const __SECRETARIA_VISUALIZAR = '__secretaria.visualizar';
export const __SECRETARIA_EDITAR = '__secretaria.editar';
export const __SECRETARIA_ADICIONAR = '__secretaria.adicionar';
export const __SECRETARIA_EXPORTAR = '__secretaria.exportar';
export const __SECRETARIA_REMOVER = '__secretaria.remover';
export const __SECRETARIA_RESPONSAVEL_EDITAR = '__secretaria.responsavel.editar';
export const __SECRETARIA_UF_VISUALIZAR = '__secretaria.uf.visualizar';
export const __SECRETARIA_PEDIDO_VISUALIZAR = '__secretaria.pedido.visualizar';
export const __SECRETARIA_PEDIDO_EDITAR = '__secretaria.pedido.editar';
export const __SECRETARIA_PEDIDO_EDITAR_ALERTAS = '__secretaria.pedido.editar.alertas';
export const __SECRETARIA_EMAIL_ENVIAR_INDIVIDUAL = '__secretaria.email.enviar.individual';
export const __SECRETARIA_DASHBOARD = '__secretaria.dashboard';
export const __SECRETARIA_EMAIL = '__secretaria.email';
export const __SECRETARIA_EMAIL_REENVIAR = '__secretaria.email.reenviar';
export const __SECRETARIA_EMAIL_VISUALIZAR = '__secretaria.email.visualizar';
export const __SECRETARIA_PEDIDO_EMAIL = '__secretaria.pedido.email';
export const __SECRETARIA_PEDIDO_EMAIL_REENVIAR = '__secretaria.pedido.email.reenviar';
export const __SECRETARIA_PEDIDO_EMAIL_VISUALIZAR = '__secretaria.pedido.email.visualizar';

export const __RECIBO_EXPORTAR = '__recibo.exportar';
export const __RECIBO_ESCOLA = '__recibo.escola';
export const __RECIBO_SECRETARIA = '__recibo.secretaria';

export const __RELATORIO_ESCOLA_VISUALIZAR = '__relatorio.escola.visualizar';
export const __RELATORIO_ESCOLA_EXPORTAR = '__relatorio.escola.exportar';
export const __RELATORIO_SECRETARIA_VISUALIZAR = '__relatorio.secretaria.visualizar';
export const __RELATORIO_SECRETARIA_EXPORTAR = '__relatorio.secretaria.exportar';
export const __RELATORIO_ESCOLAS_VISUALIZAR = '__relatorio.escolas.visualizar';
export const __RELATORIO_ESCOLAS_EXPORTAR = '__relatorio.escolas.exportar';
export const __RELATORIO_SECRETARIAS_VISUALIZAR = '__relatorio.secretarias.visualizar';
export const __RELATORIO_SECRETARIAS_EXPORTAR = '__relatorio.secretarias.exportar';
export const __RELATORIO_SECRETARIAS_ESTADUAIS_VISUALIZAR = '__relatorio.secretarias-estaduais.visualizar';
export const __RELATORIO_SECRETARIAS_ESTADUAIS_EXPORTAR = '__relatorio.secretarias-estaduais.exportar';
export const __RELATORIO_ESCOLAS_POR_UF_VISUALIZAR = '__relatorio.escolas-por-uf.visualizar';
export const __RELATORIO_ESCOLAS_POR_UF_EXPORTAR = '__relatorio.escolas-por-uf.exportar';
export const __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR = '__relatorio.dependencias-administrativas.visualizar';
export const __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_EXPORTAR = '__relatorio.dependencias-administrativas.exportar';
export const __RELATORIO_MUNICIPIOS_VISUALIZAR = '__relatorio.municipios.visualizar';
export const __RELATORIO_MUNICIPIOS_EXPORTAR = '__relatorio.municipios.exportar';
export const __RELATORIO_UFS_VISUALIZAR = '__relatorio.ufs.visualizar';
export const __RELATORIO_UFS_EXPORTAR = '__relatorio.ufs.exportar';

export const __DASHBOARD_VISUALIZAR = '__dashboard.visualizar';
export const __DASHBOARD_DADOS_EDITAR = '__dashboard.dados.editar';
export const __DASHBOARD_ALUNOS_PREVISTOS = '__dashboard.alunos-previstos';
export const __DASHBOARD_RECIBO = '__dashboard.recibo';
export const __DASHBOARD_PROVA = '__dashboard.prova';
export const __DASHBOARD_CARTAO = '__dashboard.cartao';
export const __DASHBOARD_MASCARA = '__dashboard.mascara';
export const __DASHBOARD_GABARITO = '__dashboard.gabarito';
export const __DASHBOARD_RELATORIO_INFORMATIVO = '__dashboard.relatorio-informativo';
export const __DASHBOARD_MANUAL = '__dashboard.manual';
export const __DASHBOARD_CARTAZ = '__dashboard.cartaz';
export const __DASHBOARD_ALUNOS_PRESENTES = '__dashboard.alunos-presentes';
export const __DASHBOARD_CERTIFICADO = '__dashboard.certificado';
export const __DASHBOARD_RELATORIO = '__dashboard.relatorio';