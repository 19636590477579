import Form from '../../views/Prova/Form';
import { __INSCRICAO_EDITAR } from '../../security/RoleConfiguration';

export const path = '/admin/inscricao';

export const listRoutes = [
    {
        path: path + '/atualizar',
        component: Form,
        roles: [ __INSCRICAO_EDITAR ],
    },
];


export default listRoutes;