import React from 'react';
import { withRouter } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web'

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Paper, Logo, Title } from './Style';

import Copyright from '../../components/Copyright/Copyright';

const SignIn = () => {
    const [keycloak, ] = useKeycloak();

    const handleSubmitSignIn = () => keycloak.login();

    return (
        <Container component="main" maxWidth="xs">
            <Paper>
                <Logo />
                <Title>{process.env.REACT_APP_NAME_GENERAL}</Title>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onClick={handleSubmitSignIn}
                >
                    Entrar
                </Button>
            </Paper>
            <Box mt={8}>
                <Copyright href="http://impa.br" >
                    IMPA
                </Copyright>
            </Box>
        </Container>
    )
}

export default withRouter(SignIn);