import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';

import { errors } from '../../services/API';
import SecretariaService from '../../services/Secretaria';
import SchoolService from '../../services/Escola';
import ResponsavelService from '../../services/Responsavel';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";

import Telefone from '../../forms/Telefone/Telefone';
import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { goToLogin } from '../../variables/Links/Auth';

import { 
    isBlank,
    isBlankHelperText,
    isNameCompoundInvalid, 
    isNameCompoundHelperText,
    isMailInvalid,
    isMailHelperText,    
    isCPFInvalid,
    isCPFHelperText,
    isEmailsDiffHelperText,
    isPasswordsDiffHelperText
} from '../../helper/ValidationHelper';
import { decrypt } from '../../helper/EncryptHelper';
import { cpfMask } from '../../helper/MaskHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsible: {
                name: '',
                email: '',
                emailRepeat: '',
                cpf: '',
                cargo: '',
                password: '',
                passwordRepeat: '',
            },
            hash: queryString.parse(this.props.location.search).hash.replaceAll(' ', '+'),
            type: this.props.match.params.type,
            entity: { 
                id: '',
                name: '',
            },
            errors: {
                name: false,
                email: false,
                emailRepeat: false,
                cpf: false,
                cargo: false,
                password: false,
                passwordRepeat: false,
            },
            helpers: {
                name: null,
                email: null,
                emailRepeat: null,
                cpf: null,
                cargo: null,
                password: null,
                passwordRepeat: null,
            },
            loadingButtonSave: false,
            saveDisabled: true,
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            responsible: {
                ...this.state.responsible, 
                [e.target.name]: e.target.name === 'cpf' ? cpfMask(e.target.value): e.target.value 
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
        });
    };
    
    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setTelefone = form => this.Telefone = form;

    // VALIDAÇÕES
    isValid = () => {
        if ( (isBlank(this.state.responsible.name) || isNameCompoundInvalid(this.state.responsible.name)) || 
                isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) || isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat) ||
                isBlank(this.state.responsible.cpf) || isCPFInvalid(this.state.responsible.cpf) || isBlank(this.state.responsible.cargo) || isBlank(this.state.responsible.password) || isBlank(this.state.responsible.passwordRepeat) ) {
           this.setState({ 
               errors: {
                   name: isBlank(this.state.responsible.name) || isNameCompoundInvalid(this.state.responsible.name) ? true : false,
                   email: isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) ? true : false,
                   emailRepeat: isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat) ? true : false,
                   cpf: isBlank(this.state.responsible.cpf) || isCPFInvalid(this.state.responsible.cpf) ? true : false,
                   cargo: isBlank(this.state.responsible.cargo) ? true : false,
                   password: isBlank(this.state.responsible.password) ? true : false,
                   passwordRepeat: isBlank(this.state.responsible.passwordRepeat) ? true : false,
               },
                helpers: {
                    name: isBlank(this.state.responsible.name) ? isBlankHelperText() : isNameCompoundInvalid(this.state.responsible.name) ? isNameCompoundHelperText() : null,
                    email: isBlank(this.state.responsible.email) ? isBlankHelperText() : isMailInvalid(this.state.responsible.email) ? isMailHelperText() : null,
                    emailRepeat: isBlank(this.state.responsible.emailRepeat) ? isBlankHelperText() : isMailInvalid(this.state.responsible.emailRepeat) ? isMailHelperText() : null,
                    cpf: isBlank(this.state.responsible.cpf) ? isBlankHelperText() :  isCPFInvalid(this.state.responsible.cpf) ? isCPFHelperText() : null,
                    cargo: isBlank(this.state.responsible.cargo) ? isBlankHelperText() : null,
                    password: isBlank(this.state.responsible.password) ? isBlankHelperText() : null,
                    passwordRepeat: isBlank(this.state.responsible.passwordRepeat) ? isBlankHelperText() : null,
                },
           });
           return false;

        } else if (this.state.responsible.email !== this.state.responsible.emailRepeat) {
            this.setState({ 
                errors: {
                    email: true,
                    emailRepeat: true,
                },
                helpers: {
                    emailRepeat: isEmailsDiffHelperText(),
                },
            });
            return false;

        } else if (this.state.responsible.password !== this.state.responsible.passwordRepeat) {
           this.setState({ 
               errors: {
                   password: true,
                   passwordRepeat: true,
               },
               helpers: {
                    passwordRepeat: isPasswordsDiffHelperText(),
                },
           });
           return false;
       }
       return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();

        if ( !formIsValid || !formTelefoneIsValid )  {
            this.setState({ loadingButtonSave: false });
            return
        }       

        const responsible = {
            nome: this.state.responsible.name,
            email: this.state.responsible.email,
            nmCpf: this.state.responsible.cpf,
            username: this.state.responsible.email,
            nmCargo: this.state.responsible.cargo,
            password: this.state.responsible.password,
            cdEscola: this.state.type === 'escola' ? this.state.entity.id : null ,
            cdSecretariaEducacao: this.state.type === 'secretaria' ? this.state.entity.id : null,

            telefones: [...this.Telefone.state.phones],
        }

        ResponsavelService.add(responsible)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Responsável pela Inscrição Cadastrado com Sucesso. Você Será Redirecionado para a Página de Login em Instantes",
                        type: "success",
                        open: true
                    }
                });
                setTimeout(() => goToLogin(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    componentDidMount() {
        let request;

        const f = [{ field: 'comResponsavel', value: false }];

        if (this.state.type === 'secretaria') {
            request = SecretariaService;
            f.push({ field: 'cdSecretariaEducacao', value: decrypt(this.state.hash) })
        } else {
            request = SchoolService;
            f.push({ field: 'cdMecEscola', value: decrypt(this.state.hash) })
        }

        request.filters(f)
            .then(res => {
                if(res.data.content.length > 0) {
                    this.setState({
                        entity: {
                            id: this.state.type === 'secretaria' ? res.data.content[0].cdSecretariaEducacao : res.data.content[0].cdMecEscola,
                            name: this.state.type === 'secretaria' ? res.data.content[0].nmSecretariaEducacao : res.data.content[0].nmMecEscola,
                        },
                        saveDisabled: false,
                    });
                } else {
                    f.splice(0, 1);
                    f.push({ field: 'comResponsavel', value: 'true' });

                    request.filters(f)
                        .then( res => {
                            if(res.data.content.length > 0) {
                                this.Toast.setState({
                                    message: {
                                        message: `A ${ this.state.type === 'secretaria' ? `Secretaria` : `Escola` } Já Cadastrou um Responsável para Inscrevê-la.`,
                                        type: 'error',
                                        open: true
                                    }
                                })
                                setTimeout(() => goToLogin(), 3000);
                            } else {
                                this.Toast.setState({
                                    message: {
                                        message: `A ${ this.state.type === 'secretaria' ? `Secretaria` : `Escola` } Não Foi Encontrada para Vínculo.`,
                                        type: 'error',
                                        open: true
                                    }
                                })
                                setTimeout(() => goToLogin(), 3000);
                            }
                        })
                        .catch(err => {
                            this.Toast.setState({
                                message: {
                                    message: `Não Foi Possível Buscar a ${ this.state.type === 'secretaria' ? `Secretaria` : `Escola` } para Vínculo.`,
                                    type: 'error',
                                    open: true
                                }
                            })
                           setTimeout(() => goToLogin(), 3000);
                        })
                }
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: `Não Foi Possível Buscar a ${ this.state.type === 'secretaria' ? `Secretaria` : `Escola` } para Vínculo.`,
                        type: 'error',
                        open: true
                    }
                })
                setTimeout(() => goToLogin(), 3000);
            })
    }

    render () {
        const page = `Cadastrar Responsável pela Inscrição`;
        const links = [];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>
                    {page}
                    <small> - {this.state.entity.name}</small>
                </Title>

                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="name"
                            label="Nome Completo do Responsável"
                            value={this.state.responsible.name}
                            error={this.state.errors.name}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.name}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="email"
                            label="Email do Responsável"
                            value={this.state.responsible.email}
                            error={this.state.errors.email}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.email}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="emailRepeat"
                            label="Repetir Email do Responsável"
                            value={this.state.responsible.emailRepeat}
                            error={this.state.errors.emailRepeat}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.emailRepeat}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={2}>
                        <Text
                            required
                            maxLength='14'
                            name="cpf"
                            label="CPF do Responsável"
                            value={this.state.responsible.cpf}
                            error={this.state.errors.cpf}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cpf}
                        />
                    </Grid>                
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="cargo"
                            label="Cargo (Função)"
                            value={this.state.responsible.cargo}
                            error={this.state.errors.cargo}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cargo}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={3}>
                        <Text
                            name="username"
                            label="Usuário"
                            value={this.state.responsible.email}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            required
                            type="password"
                            name="password"
                            label="Senha"
                            value={this.state.responsible.password}
                            onChange={this.handleChange}
                            error={this.state.errors.password}
                            helperText={this.state.helpers.password}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            required
                            type="password"
                            name="passwordRepeat"
                            label="Repetir Senha"
                            value={this.state.responsible.passwordRepeat}
                            onChange={this.handleChange}
                            error={this.state.errors.passwordRepeat}
                            helperText={this.state.helpers.passwordRepeat}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </Grid>
                </Grid>
                <Telefone parentRef={this.setTelefone} />
                <ButtonsForm 
                    idFocus={`saveButton`}
                    onClick={this.handleSubmit} 
                    saveDisabled={this.state.saveDisabled} 
                    onBack={() => goToLogin()} 
                    loading={this.state.loadingButtonSave} 
                />
            </>
        )
    }
}

export default withRouter(Form);