import React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import logo from '../../assets/img/logo.png';

const useStyles = makeStyles( 
    (theme) => (
        {
            paper: {
                marginTop: theme.spacing(8),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            form: {
                width: '100%',
                marginTop: theme.spacing(1),
            },
            logo: {
                width: '35%',
                height: 'auto',
                margin: theme.spacing(2),
            },
            subtitle: {
                marginTop: theme.spacing(2),
                fontSize: '1.3em',
            },
        }
    ) 
);

export const Paper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.paper}>
            {props.children}
        </div>
    )
}

export const Logo = () => {
    const classes = useStyles();
    return <img src={logo} className={classes.logo} alt={process.env.REACT_APP_NAME_GENERAL} />
}

export const Form = (props) => {
    const classes = useStyles();
    return (
        <form className={classes.form} {...props}>
            {props.children}
        </form>
    )
}

export const Title = (props) => {
    return (
        <Typography component="h1" variant="h5">
            {props.children}
        </Typography>
    )
}

export const SubTitle = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.subtitle} component="h3" variant="h6">
            {props.children}
        </Typography>
    )
}

export default useStyles;
