import React, { Fragment, useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Brightness6OutlinedIcon from '@material-ui/icons/Brightness6Outlined';
import Switch from '@material-ui/core/Switch';

import useStyles from './Style';
import List from './List';

import menuList from '../../variables/Links/Menu';

const ThemeMode = (props) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(props.darkMode) 

  const handleChange = () => {
    setChecked(!checked);
    props.setDarkMode(!checked);
  };

  return (
    <div className={classes.darkMode}>
      <Brightness6OutlinedIcon />
      <Switch
        checked={checked}
        onChange={handleChange}
        color="primary"
        name="darkMode"
      />
    </div>
  );
}

const DrawerLeft = (props) => {
  const classes = useStyles();

  let listItems = menuList.map( (menu, key) => {     
    return (
      <Fragment key={key} >
        <List items={menu.items} category={menu.category} />
      </Fragment>
    )
  });  

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={props.click}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      { listItems }

      <div className={classes.drawerEnd}>
        <ThemeMode 
          darkMode={props.darkMode}
          setDarkMode={props.setDarkMode}
        />
      </div>
    </Drawer>
  );
}

export default DrawerLeft;