import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import ContextoService from '../../services/Contexto';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { optionsType } from '../../variables/Enums/Contexto';

import { 
    isBlank, 
    isBlankHelperText 
} from '../../helper/ValidationHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                name: '',
                type: '',
                value: '',
            },
            errors: {
                name: false,
                type: false,
            },
            helpers: {
                name: null,
                type: null,
            },
            typeInitial: '',
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: {
                path: null,
                state: null,
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            context: {...this.state.context, [e.target.name]: e.target.value },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // VALIDAÇÃO
    isValid = () => {
        if ( isBlank(this.state.context.name) || isBlank(this.state.context.type) ) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.context.name) ? true : false,
                    type: isBlank(this.state.context.type) ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.context.name) ? isBlankHelperText() : null,
                    type: isBlank(this.state.context.type) ? isBlankHelperText() : null,
                },
            })
            return false
        }
        return true
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid()
        
        if ( !formIsValid ) {
            this.setState({ loadingButtonSave: false });
            return
        }       

        const context = {
            nmTipo: this.state.context.type,
            nmVariavelContexto: this.state.context.name,
            nmValor: this.state.typeInitial === this.state.context.type ? this.state.context.value : null,
        } 
        
        let msgSuccess = '';
        let request = '';

        if (this.state.context.id) {
            request = ContextoService.edit(this.state.context.id, context);
            msgSuccess = "Variável de Contexto Atualizada com Sucesso.";
        } else {
            msgSuccess = "Variável de Contexto Criada com Sucesso.";
            request = ContextoService.add(context);
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    componentDidMount() {
        this.filterPersistence(this.props.location);

        if (this.state.context.id) {
            ContextoService.find(this.state.context.id)
                .then(res => {
                    this.setState({
                        context: {
                            id: res.data.cdVariavel,
                            name: res.data.nmVariavelContexto,
                            type: res.data.nmTipo,
                            value: res.data.nmValor,
                        },
                        typeInitial: res.data.nmTipo,
                        loading: false,
                    });
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Variável de Contexto para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: { 
                history: {
                state: this.state.history.state
                }
            }
        });    
    }

    render () {
        const page = this.state.context.id ? 'Editar Variável' : 'Adicionar Variável';
        const links = [
            {
                path: '/admin/contexto',
                name: 'Contexto'
            },
            {
                path: '/admin/contexto/listagem',
                name: 'Listagem'
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={3}>
                                <Text
                                    required
                                    name="name"
                                    label="Nome da Variável"
                                    value={this.state.context.name}
                                    error={this.state.errors.name}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.name}
                                />
                            </Grid>
                            <Grid item sm={12} lg={3}>
                                <SelectOption 
                                    required
                                    name="type"
                                    label="Tipo de Variavel"
                                    options={optionsType}
                                    value={this.state.context.type}
                                    error={this.state.errors.type}
                                    onChange={this.handleChange}
                                    hiddenblank="true"
                                    helperText={this.state.helpers.type}
                                />
                            </Grid>
                            <ButtonsForm 
                                onClick={this.handleSubmit} 
                                onBack={this.state.history.path ? () => this.handleBack() : null} 
                                idFocus={`saveButton`} 
                                loading={this.state.loadingButtonSave} 
                            />
                        </Grid>
                    </>
                }
            </>
        )
    }
}

export default withRouter(Form);