import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { 
  isPeriodoInscricoes,
  isPeriodoDownloadProva,
  isPeriodoDownloadCartao,
  isPeriodoDownloadMascara,
  isPeriodoDownloadGabarito,
  isPeriodoRegistroParticipacao,
  isPeriodoDownloadRelatorioInformativo,
  isPeriodoDownloadManual,
  isPeriodoDownloadCartaz,
  isPeriodoDownloadCertificado,
  isAfterDataProva,
} from '../../helper/ContextHelper';
import { locationResponsavelParse } from '../../helper/ParseStateHelper';
import { getFilenameResponse } from '../../helper/FileHelper';

import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SchoolIcon from '@material-ui/icons/School';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import BookIcon from '@material-ui/icons/Book';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import Divider from '@material-ui/core/Divider';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";
import Boxer from '../../components/Boxer/Boxer';
import Loading from '../../components/Loading/Loading';

import DadosEscola from '../../forms/Relatorio/DadosEscola';
import DadosSecretaria from '../../forms/Relatorio/DadosSecretaria';
import DadosResponsavel from '../../forms/Relatorio/DadosResponsavel';

import { makeStyles } from '@material-ui/core/styles';

import AuthorizedElement from '../../security/AuthorizedElement';
import { 
  __DASHBOARD_DADOS_EDITAR,
  __DASHBOARD_RECIBO,
  __DASHBOARD_PROVA,
  __DASHBOARD_CARTAO,
  __DASHBOARD_MASCARA,
  __DASHBOARD_GABARITO,
  __DASHBOARD_RELATORIO_INFORMATIVO,
  __DASHBOARD_MANUAL,
  __DASHBOARD_CARTAZ,
  __DASHBOARD_ALUNOS_PRESENTES,
  __DASHBOARD_CERTIFICADO,
  __DASHBOARD_RELATORIO,
  __DASHBOARD_ALUNOS_PREVISTOS,
} from '../../security/RoleConfiguration';

import ArquivoService from '../../services/Arquivo';
import DashboardService from '../../services/Dashboard';
import { errors } from '../../services/API';

export const useStyles = makeStyles( theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

const SECRETARIA = 'SECRETARIA';
const ESCOLA = 'ESCOLA';

const ButtonEdit = ({ id })  => {
  return (
    <Grid item >
      <ButtonPrimary
        startIcon={<EditIcon />}
        to={{ pathname: `/admin/secretaria/responsavel/editar/${id}`, state: { responsavel: id }}}
        component={Link}
        name="Editar Seus Dados de Contato"
      />
    </Grid>
  )
}

const ButtonInformarAlunosPrevistos = ({ tipo, id })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        startIcon={<SchoolIcon />}
        to={{ pathname: `/admin/inscricao/cadastrar`, state: { responsavel: id }}}
        component={Link}
        name={ tipo === SECRETARIA ? "Selecionar Escolas e Informar Quantidade de Alunos" : "Informar Quantidade de Alunos" }
      />
    </Grid>
  )
}

const ButtonRecibo = ({ tipo, id })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={2} className={classes.body}>
      <ButtonPrimary
        startIcon={<ReceiptIcon />}
        to={{ pathname: tipo === SECRETARIA ? '/admin/recibo/secretaria' : '/admin/recibo/escola', state: { responsavel: id }}}
        component={Link}
        name="Recibo de Inscrição"
      />
    </Grid>
  )
}

const ButtonProva = ({ prova, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={1} className={classes.body}>
      <ButtonPrimary
        startIcon={<AssignmentIcon />}
        onClick={() => handleDownloadClick(prova)}
        name="Prova"
      />
    </Grid>
  )
}

const ButtonCartao = ({ cartao, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={2} className={classes.body}>
      <ButtonPrimary
        startIcon={<ConfirmationNumberIcon />}
        onClick={() => handleDownloadClick(cartao)}
        name="Cartão Resposta"
      />
    </Grid>
  )
}

const ButtonGabarito = ({ gabarito, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={1} className={classes.body}>
      <ButtonPrimary
        startIcon={<AssignmentTurnedInIcon />}
        onClick={() => handleDownloadClick(gabarito)}
        name="Gabarito"
      />
    </Grid>
  )
}

const ButtonMascara = ({ mascara, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={1} className={classes.body}>
      <ButtonPrimary
        startIcon={<BorderAllIcon />}
        onClick={() => handleDownloadClick(mascara)}
        name="Máscara"
      />
    </Grid>
  )
}

const ButtonRelatorioInformativo = ({ relatorioInformativo, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={3} className={classes.body}>
      <ButtonPrimary
        startIcon={<AssessmentOutlinedIcon />}
        onClick={() => handleDownloadClick(relatorioInformativo)}
        name="Relatório Informativo de Participação"
      />
    </Grid>
  )
}

const ButtonManual = ({ manual, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={3} className={classes.body}>
      <ButtonPrimary
        startIcon={<ReceiptOutlinedIcon />}
        onClick={() => handleDownloadClick(manual)}
        name="Manual de Informações Complementares"
      />
    </Grid>
  )
}

const ButtonCartaz = ({ cartaz, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={2} className={classes.body}>
      <ButtonPrimary
        startIcon={<CardMembershipOutlinedIcon />}
        onClick={() => handleDownloadClick(cartaz)}
        name="Cartaz Informativo"
      />
    </Grid>
  )
}

const ButtonInformarAlunosPresentes = ({ id })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        startIcon={<FormatListNumberedRtlIcon />}
        to={{ pathname: `/admin/inscricao/atualizar`, state: { responsavel: id }}}
        component={Link}
        name="Informar Quantidade de Alunos Presentes"
      />
    </Grid>
  )
}

const ButtonCertificado = ({ certificado, handleDownloadClick })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={3} className={classes.body}>
      <ButtonPrimary
        startIcon={<BookIcon />}
        onClick={() => handleDownloadClick(certificado)}
        name="Certificado de Participação"
      />
    </Grid>
  )
}

const ButtonRelatorio = ({ tipo, id })  => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={3} className={classes.body}>
      <ButtonPrimary
        startIcon={<AssessmentIcon />}
        to={{ pathname: tipo === SECRETARIA ? '/admin/relatorio/secretaria' : '/admin/relatorio/escola', state: { responsavel: id }}}
        component={Link}
        name="Relatório de Participação"
      />
    </Grid>
  )
}

export class Home extends Component {
  constructor() {
    super()
    this.state = {
      prova: null,
      cartao: null,
      gabarito: null,
      mascara: null,
      relatorioInformativo: null,
      manual: null,
      cartaz: null,
      certificado: null,
      responsavel: {
        id: null,
        tipo: null,
        temInscricao: false
      },
      loading: true,
    }
  }

  // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosEscola = d => this.DadosEscola = d;
  setDadosSecretaria = d => this.DadosSecretaria = d;
  setDadosResponsavel = d => this.DadosResponsavel = d;

  // DOWNLOAD DE PROVA E GABARITO
  handleDownloadClick = (id) => {
    this.Toast.setState({
      message: {
        message: "Em Instantes o Download Iniciará Automáticamente",
        type: "info",
        open: true
      }
    });

    ArquivoService.download(id)
      .then(res => {
        FileDownload(res.data, getFilenameResponse(res));
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  async componentDidMount () {
    this.props.loadContext();

    const f = locationResponsavelParse(this.props.location);

    await DashboardService.init(f)
      .then(res => {
        this.setState({
          prova: res.data.prova,
          cartao: res.data.cartao,
          gabarito: res.data.gabarito,
          mascara: res.data.mascara,
          relatorioInformativo: res.data.relatorioInformativo,
          manual: res.data.manual,
          cartaz: res.data.cartaz,
          certificado: res.data.certificado,
          responsavel: {
            id: res.data.responsavel,
            tipo: res.data.tipo,
            temInscricao: res.data.temInscricao
          },
          loading: false,
        });

        if (res.data.tipo === ESCOLA) {
          this.DadosEscola.setState({
            school: {
              nmDependenciaAdministrativaEscola: res.data.dependenciaAdministrativa,
              nmEscola: res.data.nome,
              ufEscola: res.data.uf,
              municipioEscola: res.data.municipio
            },
          })
        } else if (res.data.tipo === SECRETARIA) {
          this.DadosSecretaria.setState({
            secretary: {
              nmDependenciaAdministrativaSecretaria: res.data.dependenciaAdministrativa,
              nmSecretaria: res.data.nome,
              ufSecretaria: res.data.uf,
              municipioSecretaria: res.data.municipio
            },
          })
        }

        this.DadosResponsavel.setState({
          responsible: {
            emailResponsavel: res.data.emailResponsavel,
            nmCpfResponsavel: res.data.nmCpfResponsavel,
            nomeResponsavel: res.data.nomeResponsavel,
            telefonesResponsavel: [...res.data.telefonesResponsavel]
          },
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      });
  }

  render () {
    const page = 'Início';
    const links = [];
    const contexts = this.props.contexts;

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={page} />
          </Grid>
        </Grid>
        <Title>{page}</Title>

        <Grid container spacing={3}>
          { this.state.loading ? 
            <Loading />
          :
            <>
              { this.state.responsavel.id &&
                <AuthorizedElement roles={[__DASHBOARD_DADOS_EDITAR]}>
                  { this.state.responsavel.tipo === ESCOLA ? 
                    <Grid item xs={5} lg={5} sm={12}>
                      <DadosEscola parentRef={this.setDadosEscola} />
                    </Grid>
                  : this.state.responsavel.tipo === SECRETARIA ? 
                      <Grid item xs={5} lg={5} sm={12}>
                        <DadosSecretaria parentRef={this.setDadosSecretaria} />
                      </Grid>
                    : null
                  }
                  <Grid item xs={5} lg={5} sm={12}>
                    <DadosResponsavel parentRef={this.setDadosResponsavel} />
                  </Grid>
                  <Grid container item xs={2} lg={2} sm={12} justifyContent='flex-end' alignItems='center'>
                    <ButtonEdit id={this.state.responsavel.id} />
                  </Grid>
                </AuthorizedElement>
              }

              <AuthorizedElement roles={[__DASHBOARD_ALUNOS_PREVISTOS, __DASHBOARD_RECIBO]}>
                { (isPeriodoInscricoes(contexts) || this.state.responsavel.temInscricao) && this.state.responsavel.tipo && this.state.responsavel.id &&
                  <Grid item xs={12}>
                    <Boxer category="Inscrição">
                      <AuthorizedElement roles={[__DASHBOARD_ALUNOS_PREVISTOS]}>
                        { isPeriodoInscricoes(contexts) && this.state.responsavel.tipo && this.state.responsavel.id &&
                          <ButtonInformarAlunosPrevistos tipo={this.state.responsavel.tipo} id={this.state.responsavel.id} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_RECIBO]}>
                        { this.state.responsavel.temInscricao && this.state.responsavel.tipo && this.state.responsavel.id &&
                          <ButtonRecibo tipo={this.state.responsavel.tipo} id={this.state.responsavel.id} />
                        }
                      </AuthorizedElement>
                    </Boxer>
                  </Grid>
                }
              </AuthorizedElement>

              <AuthorizedElement roles={[__DASHBOARD_PROVA, __DASHBOARD_CARTAO, __DASHBOARD_MASCARA, __DASHBOARD_GABARITO, __DASHBOARD_RELATORIO_INFORMATIVO, __DASHBOARD_MANUAL, __DASHBOARD_CARTAZ, __DASHBOARD_ALUNOS_PRESENTES]}>
                { (((isPeriodoDownloadProva(contexts) && this.state.prova)
                    || (isPeriodoDownloadCartao(contexts) && this.state.cartao) 
                    || (isPeriodoDownloadMascara(contexts) && this.state.mascara)
                    || (isPeriodoDownloadGabarito(contexts) && this.state.gabarito)
                    || (isPeriodoDownloadRelatorioInformativo(contexts) && this.state.relatorioInformativo)
                    || (isPeriodoDownloadManual(contexts) && this.state.manual)
                    || (isPeriodoDownloadCartaz(contexts) && this.state.cartaz)
                    || (isPeriodoRegistroParticipacao(contexts) && this.state.responsavel.id)) 
                    && this.state.responsavel.temInscricao) &&
                  <Grid item xs={12}>
                    <Boxer category="Prova">
                      <AuthorizedElement roles={[__DASHBOARD_ALUNOS_PRESENTES]}>
                        { (isPeriodoRegistroParticipacao(contexts) && this.state.responsavel.id && this.state.responsavel.temInscricao) &&
                          <ButtonInformarAlunosPresentes id={this.state.responsavel.id} />
                        }
                        <Divider />
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_PROVA]}>
                        { (isPeriodoDownloadProva(contexts) && this.state.prova && this.state.responsavel.temInscricao) && 
                          <ButtonProva prova={this.state.prova} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_CARTAO]}>
                        { (isPeriodoDownloadCartao(contexts) && this.state.cartao && this.state.responsavel.temInscricao) && 
                          <ButtonCartao cartao={this.state.cartao} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_MASCARA]}>
                        { (isPeriodoDownloadMascara(contexts) && this.state.mascara && this.state.responsavel.temInscricao) && 
                          <ButtonMascara mascara={this.state.mascara} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_GABARITO]}>
                        { (isPeriodoDownloadGabarito(contexts) && this.state.gabarito && this.state.responsavel.temInscricao) && 
                          <ButtonGabarito gabarito={this.state.gabarito} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_RELATORIO_INFORMATIVO]}>
                        { (isPeriodoDownloadRelatorioInformativo(contexts) && this.state.relatorioInformativo && this.state.responsavel.temInscricao) && 
                          <ButtonRelatorioInformativo relatorioInformativo={this.state.relatorioInformativo} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_MANUAL]}>
                        { (isPeriodoDownloadManual(contexts) && this.state.manual && this.state.responsavel.temInscricao) && 
                          <ButtonManual manual={this.state.manual} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_CARTAZ]}>
                        { (isPeriodoDownloadCartaz(contexts) && this.state.cartaz && this.state.responsavel.temInscricao) && 
                          <ButtonCartaz cartaz={this.state.cartaz} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                    </Boxer>
                  </Grid>
                }
              </AuthorizedElement>

              <AuthorizedElement roles={[__DASHBOARD_CERTIFICADO, __DASHBOARD_RELATORIO]}>
                { (((isAfterDataProva(contexts) && this.state.responsavel.tipo && this.state.responsavel.id) 
                    || (this.state.certificado && isPeriodoDownloadCertificado(contexts))) 
                  && this.state.responsavel.temInscricao) &&
                  <Grid item xs={12}>
                    <Boxer category="Relatórios">
                      <AuthorizedElement roles={[__DASHBOARD_CERTIFICADO]}>
                        { (isPeriodoDownloadCertificado(contexts) && this.state.certificado && this.state.responsavel.temInscricao) &&
                          <ButtonCertificado certificado={this.state.certificado} handleDownloadClick={this.handleDownloadClick} />
                        }
                      </AuthorizedElement>
                      <AuthorizedElement roles={[__DASHBOARD_RELATORIO]}>
                        { (isAfterDataProva(contexts) && this.state.responsavel.tipo && this.state.responsavel.id && this.state.responsavel.temInscricao) &&
                          <ButtonRelatorio tipo={this.state.responsavel.tipo} id={this.state.responsavel.id} />
                        }
                      </AuthorizedElement>
                    </Boxer>
                  </Grid>
                }
              </AuthorizedElement>
            </>
          }
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ( { contexts: state.contexts } );
const mapDispatchToProps = dispatch => ( bindActionCreators({ loadContext }, dispatch) );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));