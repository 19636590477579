import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import EscolaService from '../../services/Escola';
import ResponsavelService from '../../services/Responsavel';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import Endereco from '../../forms/Endereco/Endereco';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativa';

import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';
import { optionsOrigem, optionsStatus } from '../../variables/Enums/Escola';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { 
    isBlank,
    isBlankHelperText, 
    isMecCodeHelperText, 
    isMecCodeInvalid,
    isMailInvalid,
    isMailHelperText,
    isNameSchoolCompoundInvalid,
    isNameSchoolCompoundHelperText
} from '../../helper/ValidationHelper';
import { mecCodeMask } from '../../helper/MaskHelper';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __ESCOLA_ADICIONAR_OPCOES_AVANCADAS } from '../../security/RoleConfiguration';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            school: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                mecCode: '',
                name: '',
                email: '',
                status: '',
                origem: '',
            },
            errors: {
                mecCode: false,
                name: false,
                email: false,
                status: false,
                origem: false,
            },
            helpers: {
                mecCode: null,
                name: null,
                email: null,
                status: null,
                origem: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            userType: null,
            responsavel: null,
            secretaria: null,
            history: { 
                path: null, 
                state: null
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            school: {
                ...this.state.school, 
                [e.target.name]: e.target.name === 'mecCode' ? mecCodeMask(e.target.value) : e.target.value 
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: false }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setTelefone = form => this.Telefone = form;
    setEndereco = form => this.Endereco = form;
    setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.school.mecCode) || isMecCodeInvalid(this.state.school.mecCode) || isBlank(this.state.school.name) || isNameSchoolCompoundInvalid(this.state.school.name) || isBlank(this.state.school.email) || isMailInvalid(this.state.school.email) || 
                ((isBlank(this.state.school.status) || isBlank(this.state.school.origem)) && isBlank(this.state.userType)) ) {
            this.setState({ 
                errors: {
                    mecCode: isBlank(this.state.school.mecCode) || isMecCodeInvalid(this.state.school.mecCode) ? true : false,
                    name: isBlank(this.state.school.name) || isNameSchoolCompoundInvalid(this.state.school.name) ? true : false,
                    email: isBlank(this.state.school.email) || isMailInvalid(this.state.school.email) ? true : false,
                    status: isBlank(this.state.school.status) && isBlank(this.state.userType) ? true : false,
                    origem: isBlank(this.state.school.origem) && isBlank(this.state.userType) ? true : false,
                },
                helpers: {
                    mecCode: isBlank(this.state.school.mecCode) ? isBlankHelperText() : isMecCodeInvalid(this.state.school.mecCode) ? isMecCodeHelperText() : null,
                    name: isBlank(this.state.school.name) ? isBlankHelperText() : isNameSchoolCompoundInvalid(this.state.school.name) ? isNameSchoolCompoundHelperText() : null,
                    email: isBlank(this.state.school.email) ? isBlankHelperText() : isMailInvalid(this.state.school.email) ? isMailHelperText() : null,
                    status: isBlank(this.state.school.status) && isBlank(this.state.userType) ? isBlankHelperText() : null,
                    origem: isBlank(this.state.school.origem) && isBlank(this.state.userType) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid()
        const formEnderecoIsValid = this.Endereco.isValid();
        const formAdmDepIsValid = this.DependenciaAdministrativa.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();

        if (!formIsValid || !formEnderecoIsValid || !formAdmDepIsValid || !formTelefoneIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const school = {
            cdMecEscola: this.state.school.mecCode,
            nmMecEscola: this.state.school.name,
            nmEmail: this.state.school.email,

            nmCep: this.Endereco.state.address.zipCode,
            nmEndereco: this.Endereco.state.address.address,
            nmNumero: this.Endereco.state.address.number,
            nmComplemento: this.Endereco.state.address.complement,
            nmBairro: this.Endereco.state.address.district,
            cdMunicipioRegiao: this.Endereco.state.address.city,

            nmDependenciaAdministrativa: this.DependenciaAdministrativa.state.adminDep,

            nmStatus: isBlank(this.state.userType) ? this.state.school.status : 'EM_ANALISE',
            inNova: isBlank(this.state.userType) ? this.state.school.origem : true,

            telefones: [...this.Telefone.state.phones],
        }

        let msgSuccess = '';
        let request = '';

        if (this.state.school.id) {
            request = EscolaService.edit(this.state.school.id, school);
            msgSuccess = "Escola Atualizada com Sucesso.";
        } else {
            msgSuccess = "Escola Criada com Sucesso.";
            request = EscolaService.add(school);
        }
        
        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    async componentDidMount() {
        this.filterPersistence(this.props.location);

        const parseState = this.props.location.state;
        if (parseState && parseState.userType && parseState.responsavel) {
          if (parseState.userType) {
            this.setState({ userType: parseState.userType });
          }

          if (parseState.responsavel) {
            this.setState({ responsavel: parseState.responsavel });

            await ResponsavelService.find(parseState.responsavel)
                .then(res => {
                    this.setState({ 
                        secretaria: res.data.secretaria, 
                        loading: false
                    });

                    this.DependenciaAdministrativa.setState({
                        adminDep: res.data.secretaria.nmDependenciaAdministrativa,
                        disabled: {
                            adminDep: true,
                        }
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar o Responsável pela Inscrição para Validações.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
            }
        } else if (this.state.school.id) {
            await EscolaService.find(this.state.school.id)
                .then(res => {
                    this.setState({
                        school: {
                            id: res.data.cdMecEscola,
                            mecCode: res.data.cdMecEscola,
                            name: res.data.nmMecEscola,
                            email: res.data.nmEmail,
                            status: res.data.nmStatus,
                            origem: res.data.inNova,
                        },
                        loading: false
                    });

                    this.Endereco.findCities(res.data.uf.cdUf, res.data.municipio.cdMunicipioRegiao);
                    this.Endereco.setState({
                        address: {
                            zipCode: res.data.nmCep,
                            address: res.data.nmEndereco,
                            number: res.data.nmNumero,
                            complement: res.data.nmComplemento,
                            district: res.data.nmBairro,
                            city: res.data.municipio.cdMunicipioRegiao,
                            UF: res.data.uf.cdUf,
                        }
                    })

                    this.DependenciaAdministrativa.setState({
                        adminDep: res.data.nmDependenciaAdministrativa,
                    })

                    this.Telefone.setState({ 
                        phones: [...patternPhones(res.data.telefones)],
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Escola para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: { 
                history: {
                    state: this.state.history.state
                }
            }
        });    
    }

    render () {
        const page = this.state.school.id ? 'Editar Escola' : 'Adicionar Escola';
        const links = [
            {
                path: isBlank(this.state.userType) ? '/admin/escola' : null,
                name: 'Escolas'
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item container spacing={3}>
                                <Grid item sm={12} lg={2}>
                                    <Text
                                        required
                                        maxLength='8'
                                        name="mecCode"
                                        label="INEP"
                                        value={this.state.school.mecCode}
                                        error={this.state.errors.mecCode}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.mecCode}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={5}>
                                    <Text
                                        required
                                        name="name"
                                        label="Nome da Escola"
                                        value={this.state.school.name}
                                        error={this.state.errors.name}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.name}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={4}>
                                    <Text
                                        required
                                        name="email"
                                        label="Email da Escola"
                                        value={this.state.school.email}
                                        error={this.state.errors.email}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.email}
                                    />
                                </Grid>
                            </Grid>
                            <Endereco parentRef={this.setEndereco} />
                            <DependenciaAdministrativa parentRef={this.setDependenciaAdministrativa} withFederal={true} />

                            <AuthorizedElement roles={[__ESCOLA_ADICIONAR_OPCOES_AVANCADAS ]} >
                                <Grid item container spacing={3}>
                                    <Grid item sm={12} lg={2}>
                                        <SelectOption
                                            required
                                            label="Status"
                                            name='status'
                                            value={this.state.school.status}
                                            onChange={(e) => this.handleChange(e)}
                                            options={optionsStatus}
                                            hiddenblank="true"
                                            error={this.state.errors.status}
                                            helperText={this.state.helpers.status}
                                        />
                                    </Grid>
                                    <Grid item sm={12} lg={3}>
                                        <SelectOption
                                            required
                                            label="Origem"
                                            name='origem'
                                            value={this.state.school.origem}
                                            onChange={(e) => this.handleChange(e)}
                                            options={optionsOrigem}
                                            hiddenblank="true"
                                            error={this.state.errors.origem}
                                            helperText={this.state.helpers.origem}
                                        />
                                    </Grid>
                                </Grid>
                            </AuthorizedElement>
                        </Grid>
                        <Telefone parentRef={this.setTelefone} />
                        <ButtonsForm 
                            onClick={this.handleSubmit} 
                            onBack={this.state.history.path ? () => this.handleBack() : null} 
                            idFocus={`saveButton`} 
                            loading={this.state.loadingButtonSave} 
                        />
                    </>
                }
            </>
        )
    }
}

export default withRouter(Form);