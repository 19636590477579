import React, { Component } from 'react';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';

import SecretariaService from '../../services/Secretaria';
import Filters from '../../components/Filters/Filters';
import Boolean from '../../components/Inputs/Boolean/Boolean';

import { getFilenameResponse } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

import Endereco from '../Endereco/EnderecoFilter';
import { SecretariaFilterFields as Secretaria } from './SecretariaFilterFields';
import DependenciaAdministrativa from '../DependenciaAdministrativa/DependenciaAdministrativaFilter';
import ResponsavelInscricao from '../ResponsavelInscricao/ResponsavelInscricaoFilter';

export class SecretariaFilter extends Component {
  constructor(props) {
    super(props);
    props.parentRef(this);
    this.state = {
      filters: {
        withSchools: '',
      },
      exportPlan: false,
      count: 0,
    }
  }  

  // INPUTS
  handleChange = e => {
    this.setState({
      filters: {...this.state.filters,  [e.target.name]: e.target.value }
    });
  };

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
  setEndereco = form => this.Endereco = form;
  setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;
  setResponsavelInscricao = form => this.ResponsavelInscricao = form;
  setSecretaria = form => this.SecretariaFiltersFields = form;

  // APLICAR PERSISTENCIA
  handleFilterPersistence = (filters) => {
    filters = removePagingAndSorting(filters);

    this.setState({
      filters: {
        withSchools: filters.find(f =>  f.field === 'comEscolasInscritas') ? filters.find(f =>  f.field === 'comEscolasInscritas').value : "",
      },
      count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
    })

    this.DependenciaAdministrativa.setState({
      filters: {
        adminDep: filters.find(f =>  f.field === 'nmDependenciaAdministrativa') ? filters.find(f =>  f.field === 'nmDependenciaAdministrativa').value : "",
      }
    })

    this.ResponsavelInscricao.setState({
      filters: {
        nameResponsible: filters.find(f =>  f.field === 'nmResponsavel') ? filters.find(f =>  f.field === 'nmResponsavel').value : "",
        emailResponsible: filters.find(f =>  f.field === 'nmEmailResponsavel') ? filters.find(f =>  f.field === 'nmEmailResponsavel').value : "",
        withResponsible: filters.find(f =>  f.field === 'comResponsavel') ? filters.find(f =>  f.field === 'comResponsavel').value : "",
      }
    })

    this.SecretariaFiltersFields.setState({
      filters: {
        name: filters.find(f =>  f.field === 'nmSecretariaEducacao') ? filters.find(f =>  f.field === 'nmSecretariaEducacao').value : "",
        email: filters.find(f =>  f.field === 'nmEmail') ? filters.find(f =>  f.field === 'nmEmail').value : "",
      }
    })

    this.Endereco.setState({
      filters: {
        UF: filters.find(f =>  f.field === 'cdUf') ? filters.find(f =>  f.field === 'cdUf').value : "",
        city: filters.find(f =>  f.field === 'cdMunicipio') ? filters.find(f =>  f.field === 'cdMunicipio').value : "",
      }
    })
  }

  // FILTROS
  handleFilterApply = () => {
    const f = [];
    const { filters, exportPlan } = this.state;
    const filtersEndereco = this.Endereco.state.filters;
    const filtersDepAdmin = this.DependenciaAdministrativa.state.filters;
    const filtersRespInscricao = this.ResponsavelInscricao.state.filters;
    const filtersSecretaria = this.SecretariaFiltersFields.state.filters;

    if (filters.withSchools) f.push({ field: 'comEscolasInscritas', value: filters.withSchools })

    if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })

    if (filtersRespInscricao.nameResponsible) f.push({ field: 'nmResponsavel', value: filtersRespInscricao.nameResponsible })
    if (filtersRespInscricao.emailResponsible) f.push({ field: 'nmEmailResponsavel', value: filtersRespInscricao.emailResponsible })
    if (filtersRespInscricao.withResponsible) f.push({ field: 'comResponsavel', value: filtersRespInscricao.withResponsible })

    if (filtersSecretaria.name) f.push({ field: 'nmSecretariaEducacao', value: filtersSecretaria.name })
    if (filtersSecretaria.email) f.push({ field: 'nmEmail', value: filtersSecretaria.email })
    
    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersEndereco.city) f.push({ field: 'cdMunicipio', value: filtersEndereco.city })

    if (exportPlan) {
      f.push({ field: 'exportarPlanilha', value: exportPlan });

      SecretariaService.export(f)
        .then(res => {
          FileDownload(res.data, getFilenameResponse(res));
        })
    } else {
      this.props.handleFilterChange(f);
    }
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.setState({
      filters: {
        withSchools: '',
      },
      exportPlan: false,
      count: 0,
    })

    this.SecretariaFiltersFields.setState({
      filters: {
        name: '',
        email: '',
      }
    })

    this.DependenciaAdministrativa.setState({
      filters: {
        adminDep: '',
      }
    })

    this.ResponsavelInscricao.setState({
      filters: {
        nameResponsible: '',
        emailResponsible: '',
        withResponsible: '',        
      }
    })

    this.Endereco.setState({
      filters: {
        city: '',
        UF: '',
      }
    })

    this.props.handleFilterChange([], false);
  }

  // CONTAGEM DE FILTROS ATIVOS
  handleCountFilters = () => this.state.count;

  render () {
    return (
      <Filters
          handleFilter={this.handleFilterApply}
          handleFilterClear={this.handleFilterClear}
          countFilters={this.handleCountFilters}
      >
        <Grid item container spacing={3}>
          <Secretaria
            parentRef={this.setSecretaria}
          />
          <Grid item sm={12} lg={3}>
            <Boolean
                label="Com Escolas Inscritas"
                name='withSchools'
                value={this.state.filters.withSchools}
                onChange={(e) => this.handleChange(e)}
              />
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <ResponsavelInscricao 
            parentRef={this.setResponsavelInscricao}
          />
        </Grid>
        <Grid item container spacing={3}>
          <DependenciaAdministrativa 
            parentRef={this.setDependenciaAdministrativa}
            withFederal={false}
          />
          <Endereco 
            parentRef={this.setEndereco}
          />
        </Grid>
      </Filters>
    )
  }
}

export default SecretariaFilter;