import API from './API';
import { API as APIService } from './API';

const path = `pedidosSecretarias`;

const PedidoService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // EDITAR
    edit: async (id, request) => await API.edit(path, id, request),

    // ADICIONAR
    add: async request => await API.add(path, request),

    // REMOVER
    remove: async id => await API.remove(path, id),
    
    // LISTAR EMAILS
    listMails: async id => await APIService.get(`${path}/${id}/emails`),
}

export default PedidoService;