import Form from '../../views/Arquivo/Form';
import { __ARQUIVO_ADICIONAR } from '../../security/RoleConfiguration';

export const path = '/admin/arquivo';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __ARQUIVO_ADICIONAR ],
    }
];

export default listRoutes;