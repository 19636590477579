import React from 'react';
import PropTypes from 'prop-types';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import useStyles from './Style';

ScrollTop.propTypes = {
  window: PropTypes.func,
};

export function ScrollTop(props) {
  const { window } = props;
  const classes = useStyles();
  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#top');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.btnTop}>
          <Fab color="secondary" size="small" aria-label="Ir para o Topo">
              <KeyboardArrowUpIcon />
          </Fab>
      </div>
    </Zoom>
  );
}

export default ScrollTop;