import FormPedido from '../../views/Pedido/Form';

export const path = '/pedido';

export const listRoutes = [
    {
        path: path + '/',
        component: FormPedido,
        roles: [],
    },
];

export default listRoutes;