import API from './API';

const path = `emails`;

const ConviteService = {
    // ENVIAR EMAIL
    sender: async invite => await API.add(path, invite),

    // ENVIAR EMAIL DINAMICAMENTE
    senderBatch: async invites => await API.add(`${path}/convites`, invites),

    // LISTAR UM
    find: async id => await API.find(path, id),
}

export default ConviteService;