import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import { BoxerReports } from '../../components/Boxer/Boxer';

import { phoneFormatter } from '../../variables/Enums/Telefone';

export class DadosResponsavel extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            responsible: {
                nomeResponsavel: '',
                nmCpfResponsavel: '',
                emailResponsavel: '',
                telefonesResponsavel: [],
            },
        }
    }

    render () {
        return (
            <BoxerReports category="Dados do Responsável pela Inscrição">
                <Typography variant="body2">
                    Nome do Responsável: {this.state.responsible.nomeResponsavel}
                </Typography>
                <Typography variant="body2">
                    CPF: {this.state.responsible.nmCpfResponsavel}
                </Typography>
                <Typography variant="body2">
                    Email: {this.state.responsible.emailResponsavel}
                </Typography>
                <Typography variant="body2">
                    Telefones: {this.state.responsible.telefonesResponsavel.length > 0 ? this.state.responsible.telefonesResponsavel.map(phone => phoneFormatter(phone)) : "Não Informado"}
                </Typography>            
            </BoxerReports>
        )
    }
}

export default DadosResponsavel;